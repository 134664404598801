import Vue from 'vue'
import Router from 'vue-router'

import layout from '../layout'
import layoutforms from '../layoutforms'

Vue.use(Router)

export default new Router({
  linkExactActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  mode: 'history',
  base: '',
  routes: [
    {
      path: '/',
       component: {
        render (c) { return c('router-view') }
      },  //component: layout,
      children: [
        {
          path: '',
           name: 'landing_page',  // name: 'dashboard',
          component: () => import('@/upages/landing/main/landing_page') //import('@/pages/dashboard')
         }
         
      ]
    },
 
    
    
    {
      path: '/',
      component: layoutforms,
      children: [
        {
          path: 'f',
          name: 'f',
          component: () => import('@/upages/landing/forms/f')
        },
        {
          path: 'corp',
          name: 'corp',
          component: () => import('@/upages/landing/forms/corp')
        },
         {
          path: 'ask',
          name: 'ask',
          component: () => import('@/upages/landing/forms/ask')
        },
        {
          path: 'to_execute',
          name: 'to_execute',
          component: () => import('@/upages/landing/forms/to_execute')
        },
        
      ]
    },
    
    
    
    
    
    
    
    
   
    
    
    
    {
      path: '/forms',
      component: layout,
      children: [
        {
          path: 'addqr',
          name: 'addqr',
          component: () => import('@/upages/landing/forms/addqr')
        },
        {
          path: 'tlist',
          name: 'tlist',
          component: () => import('@/upages/landing/forms/tlist')
        },
       
        
        
        ]
    },
    
    
    
    
      
       
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    {
      path: '*',
      redirect: '/error-404',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: 'error-404',
          component: () => import('@/pages/samples/error-pages/error-404')
        }
      ]
    }
  ]
})
