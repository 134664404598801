<template>
  <section class="main-view">
    <div class="container-scroller">
      <Header/>
      <div class="container-fluid page-body-wrapper">
        <div class="main-panel">
          <div class="content-wrapper">
            <router-view></router-view>
          </div> <!-- content wrapper ends -->
          <Footer/>
        </div> <!-- main panel ends -->
      </div> <!-- page-body-wrapper ends -->
    </div>
  </section>
</template>

<script>
  import Header from "./partials/Header";
  // import Sidebar from "./partials/Sidebar";
  import Footer from "./partials/Footer";
  export default {
    name: "layoutchild",
    components: {
      Header,
      // Sidebar,
      Footer
    }
  }
</script>

<style scoped>
/* сделать чтобы уезжал олготип и меню, и чтобы убирался отступ, появившийся из-за уехавшего логотипа и меню - уже в компоненте*/
@media (max-width: 991px){
.page-body-wrapper {
   padding-top: unset!important; 
}  
}

</style>