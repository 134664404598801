<template>
  <section class="app-footer">
    <footer class="footer">
      <div class="container-fluid clearfix">
          <span class="text-muted d-block text-center text-sm-left d-sm-inline-block">© 2022 <a target='_blank' class="" href="https://lvrc.ru/">ЛВР Сервис</a>. Все права защищены.</span>
        <span class="float-none float-sm-right d-block mt-1 mt-sm-0 text-center text-muted">
            <!--Hand-crafted & made with <i class="mdi mdi-heart text-danger"></i>
             Icons made by <a href="https://www.flaticon.com/authors/freepik" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a>, <a href="https://www.flaticon.com/authors/smashicons" title="Smashicons">Smashicons</a>
      -->
            </span>
      </div>
    </footer>
   
  </section>
</template>

<script>
export default {
  name: 'app-footer',
  
  mounted() {
      
   
  },
}
</script>