<template>
   
</template>

<script>
export default {
  name: 'app-footer',
  
  mounted() {
      
   
    
  },
  
}
</script>