<template>
  <b-navbar id="template-header" class="navbar default-layout-navbar p-0" toggleable="lg">
      
   <vue-snotify></vue-snotify>
      
          
                        
                        
                        
                       
                        
 <div   class="horizontal-menu">
                            
                                  
                        
                         <!-- 1) TOP LINE -->

                        <nav class="navbar top-navbar col-lg-12 col-12 p-0">
                        <div class="container">
                          <div
                            class="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center"
                          >
                            
                             <div class="menu_menu_logoscontent"> 
                              <div class="menu_logos  d-flex align-items-left justify-content-left" >
                              
                                  
                                    <div  >
                                        <a class="navbar-brand" href='/'> 
                                         <img class='' style='max-width:130px; height:20px;' src="/resource/logo/logo_blue_right.png"  /> 
                                          </a>
                                    </div>
                                   
                                   <!--  подплавлено в шаблоне css \src\assets\scss\_horizontal-navbar.scss   @media (max-width: 991px) {  width: calc(100% - 110px); при одном логотипе было -55px -->

                               
                                   
                           </div>
                          </div>  
                              
                              
                          </div>   
                            
                            
                            
                          <div class="navbar-menu-wrapper d-flex  justify-content-center">  <!--  justify-content-end -->
                     <!--       <ul class="navbar-nav mr-lg-2">
                              <li class="nav-item nav-search d-none d-lg-block">
                                <div class="input-group">
                                  <div class="input-group-prepend hover-cursor" id="navbar-search-icon">
                                    <span class="input-group-text" id="search">
                                      <i class="mdi mdi-magnify"></i>
                                    </span>
                                  </div>
                                  <input
                                    type="text"
                                    class="form-control"
                                    id="navbar-search-input"
                                    placeholder="Search"
                                    aria-label="search"
                                    aria-describedby="search"
                                  />
                                </div>
                              </li>
                            </ul>-->
                            <b-navbar-nav class="navbar-nav-right ml-auto ">
                              <b-nav-item-dropdown right class="nav-profile">
                                <template slot="button-content">
                                  <span
                                    class="nav-link dropdown-toggle"
                                    id="profileDropdown"
                                    href="javascript:void(0);"
                                    data-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <div class="nav-profile-img">
                                     <img v-bind:src="rlhdata['teacher_parent_img']" alt="image" />
                                    </div>
                                    <div class="nav-profile-text">
                                      <p class="mb-0 text-black">{{ this.rlhdata['user_name'] }}  </p>
                                      <span class="d-block font-13 text-muted font-weight-normal pt-1">
                                        Профиль
                                        <i class="mdi mdi-chevron-down"></i>
                                      </span>
                                    </div>
                                  </span>
                                </template> 
                                
                                  <!--
                                    <b-dropdown-item class="preview-item" v-on:click="to_profile">
                                        <i class="mdi mdi-cached mr-2 text-success"></i> Профиль
                                    </b-dropdown-item>
                               -->
                                  
                                <b-dropdown-item class="preview-item" v-on:click="logout">
                                  <i class="mdi mdi-logout mr-2 text-primary"></i> Выйти
                                </b-dropdown-item>
                              </b-nav-item-dropdown>
                            </b-navbar-nav>
                            <button class="navbar-toggler navbar-toggler-right align-self-center"
                              @click="toggleMobileSidebar()">
                              <span class="mdi mdi-menu"></span>
                            </button>
                          </div>
                        </div>
                      </nav>

                        <!-- 2) MENU -->
                        <nav class="bottom-navbar" >
                        <div class="container align-items-start ">
                          <ul class="nav page-navigation  d-flex align-items-center justify-content-left" style="justify-content: flex-start;"   >

                            
                        
                              
                           <li class="nav-item" style="color:white;">
                              <router-link class="nav-link" v-bind:to="'/'+'?t='+$root.add_v()"  > 
                                <i class="mdi mdi-information menu-icon" style="color:white;"></i>
                                <span class="menu-title" style="color:white;">Как пользоваться QR-кодом</span>
                             </router-link> 
                            </li>    
                              
                            
                            <!--
                             <li class="nav-item" style="color:white;">
                             <router-link class="nav-link" v-bind:to="'/lkparent/faqp/'+'?t='+$root.add_v()"  >  
                               
                                        <i class="mdi mdi-message-video menu-icon" style="color:white;"></i>
                                        <span class="menu-title" style="color:white;">Связаться с менеджером</span>
                               
                              </router-link> 
                            </li>  -->
                              
                            <!-- 
                             <li class="nav-item" style="color:white;">
                              <router-link class="nav-link"   v-bind:to="'/lkparent/choosegamesstatp/'+'?t='+$root.add_v()"> 
                         
                                <i class="mdi mdi-table menu-icon" style="color:white;"></i>
                                <span class="menu-title" style="color:white;">???</span>
                           
                              </router-link>
                            </li>
                            -->
                            
                             <!--
                              <li class="nav-item" style="color:white;">
                             <router-link class="nav-link"   v-bind:to="'/lkparent/choosegamesstatp/'+'?t='+$root.add_v()">  
                                  <a class="nav-link" target="_blank" href="https://tawk.to/chat/5f802263f0e7167d00177fdd/default">
                                <i class="mdi mdi-help-circle menu-icon" style="color:white;"></i>
                                <span class="menu-title" style="color:white;">Помощь</span>
                                  </a>
                              
                            </li>-->
                            
                            <!--
                             <li class="nav-item" style="color:white;">
                              <router-link class="nav-link" to=~"/lkparent/helpp/">
                                <i class="mdi mdi-help-circle menu-icon" style="color:white;"></i>
                                <span class="menu-title" style="color:white;">Помощь</span>
                              </router-link>
                            </li>
                              -->
<!--
                            <li class="nav-item" style="color:white;">
                              <router-link class="nav-link" to="/">
                                <i class="mdi mdi-home menu-icon"></i>
                                <span class="menu-title">Лэндинг</span>
                              </router-link>
                            </li>


-------------------------------
                             <li class="nav-item" style="color:white;">
                              <a href="javascript:void(0);" class="nav-link">
                                <i class="mdi mdi-webpack menu-icon"></i>
                                <span class="menu-title">Кабинет родителя</span>
                                <i class="menu-arrow"></i>
                              </a>
                              <div class="submenu">
                                <ul class="submenu-item">
                                  <li class="nav-item">
                                    <router-link class="nav-link" to=~"/lkparent/childlistp/">Мои дети</router-link>
                                  </li>

                                </ul>
                              </div>
                            </li>

           
                            
                            
                                 <li class="nav-item">
                              <a href="javascript:void(0);" class="nav-link">
                                <i class="mdi mdi-webpack menu-icon"></i>
                                <span class="menu-title">Что нужно знать</span>
                                <i class="menu-arrow"></i>
                              </a>
                              <div class="submenu">
                                <ul class="submenu-item">
                                  <li class="nav-item">
                                    <router-link class="nav-link" to=~"/lkparent/faqp/">Родителям</router-link>
                                  </li>

                                </ul>
                              </div>
                            </li>  
                            
                            
                            
                                         <li class="nav-item">
                                 
                              <a href="javascript:void(0);" class="nav-link">
                                <i class="mdi mdi-webpack menu-icon"></i>
                                <span class="menu-title">Трансляция</span>
                                <i class="menu-arrow"></i>
                              </a>
                              <div class="submenu">
                                <ul class="submenu-item">
                           
                                    <li class="nav-item">
                                    <router-link class="nav-link" to=~"/lkparent/streameop/">Трансляция</router-link>
                                  </li>
                                </ul>
                              </div>
                                 
                            </li>
                            
                            
                            
                            
                              <li class="nav-item">
                              <a href="javascript:void(0);" class="nav-link">
                                <i class="mdi mdi-webpack menu-icon"></i>
                                <span class="menu-title">Результаты</span>
                                <i class="menu-arrow"></i>
                              </a>
                                  
                              <div class="submenu">
                                  
                                <ul class="submenu-item">
                                    
                                <a  class="nav-link">     
                                  <li class="nav-item">
                                    <router-link  to=~"/lkparent/statp/">Статистика по играм</router-link>
                                  </li>
                                </a>  
                                    
                                  
                               
                                    

                                </ul>
                              </div>
                            </li>
                            
                            
                            
                            
                          
                            
              -->              




                          </ul>
                            
                              <div class="align-items-center" >  <!--  style="-webkit-box-align: center !important; align-items: center !important; -ms-flex-align: center !important;" -->
                             
                                  <!--
                                <a class="nav-link menu-title text-white" href="tel:+88006004159" style=" padding: 22px 10px; font-size: 0.875rem; font-weight: 300; line-height: 1; "> 8 (800) 600-41-59</a>
                                -->
                            </div>
                            
                            
                        </div>
                        </nav>
                        
                        
                            
 </div>
                        
                        
                        
                        
                        
                        
                        
                   
                        
                        
                        
                        
                        
                      
              
        
     
    
      
      
      
      
      
  </b-navbar>
</template>

<script>
import Vue from 'vue'
import Snotify, { SnotifyPosition} from 'vue-snotify'
const options = {toast: {position: SnotifyPosition.rightTop,}}
Vue.use(Snotify,options);



var data = {
        objcode:'',
        a_users: [],
        user_name: '',
        tel:0,
        id:0,
        teacher_parent_img:'',
        timer_reload_page : 0,
        is_allow_reload_page :false,
}

    
    
export default {
  name: "app-header",
  
  data () { //тюненгуемые переменные и функции
        return {
            rlhdata: data,   
            
        };
    },

  mounted() {
     
    //#####CHECK VERSION 
    //this.$root.rdata['is_loaded_version']=true;
     // this.$root.check_version(this.rlhdata,false);
     console.log("mounted HEADER "+this.$router.currentRoute.path);
      
     
    var url = new URL(window.location.href);    
    this.rlhdata['objcode']=url.searchParams.get("objcode");           
     
    
    
    this.rlhdata['teacher_parent_img']='/resource/img/ico/noname_avatar.svg';
   
      
      
    var navItems = document.querySelectorAll(
      ".horizontal-menu .page-navigation >.nav-item"
    );
    document
      .querySelectorAll(".horizontal-menu .page-navigation >.nav-item")
      .forEach(function(el) {
        el.addEventListener("click", function() {
          for (var i = 0; i < navItems.length; i++) {
            if (navItems[i] == el) {
              el.classList.toggle("show-submenu");
            } else {
              navItems[i].classList.remove("show-submenu");
            }
          }
        });
      });
      
      
      
      
  },
  methods: {
      
      show_warning: function (btm_id) {
          
          /*
1 gameregt  /lkteach/gameregt/
2 streameot  /lkteach/streameot/
3 statt /lkteach/statt/
4 streameop /lkparent/streameop/
5 statp  /lkparent/statp/
           */
          console.log(btm_id);
          switch(btm_id)
          {
              case 1:
                    if(this.$root.rdata['is_loaded_version'])
                    { 
                            if (this.$root.rdata['flags_uc']['lk_teach_is_ready_games'])
                            {
                                this.$router.push('/lkteach/gameregt/'+'?t='+this.$root.add_v()).catch(()=>{});
                                
                                return;
                            }
                    }
                  break;
                  
                  case 2:
                    if(this.$root.rdata['is_loaded_version'])
                    { 
                            if (this.$root.rdata['flags_uc']['lk_teach_is_ready_stream'])
                            {
                                this.$router.push('/lkteach/streameot/'+'?t='+this.$root.add_v()).catch(()=>{});  
                                
                                return;
                            }
                    }
                  break;
                  
                  case 3:
                    if(this.$root.rdata['is_loaded_version'])
                    { 
                            if (this.$root.rdata['flags_uc']['lk_teach_is_ready_stat'])
                            {
                                this.$router.push('/lkteach/statt/'+'?t='+this.$root.add_v()).catch(()=>{});    
                                
                                return;
                            }
                    }
                  break;
                  
                  case 4:
                    if(this.$root.rdata['is_loaded_version'])
                    { 
                            if (this.$root.rdata['flags_uc']['lk_parent_is_ready_stream'])
                            {
                                this.$router.push('/lkparent/streameop/'+'?t='+this.$root.add_v()).catch(()=>{});  
                                 
                                return;
                            }
                    }
                  break;
                  
                  case 5:
                    if(this.$root.rdata['is_loaded_version'])
                    { 
                            if (this.$root.rdata['flags_uc']['lk_parent_is_ready_stat'])
                            {
                                this.$router.push('/lkparent/statp/'+'?t='+this.$root.add_v()).catch(()=>{});  
                                  
                                return;
                            }
                    }
                  break;
                  
            default:
                        
               break;
              
          }
          
          
          if(this.$root.rdata['is_loaded_version'])
                    { 
                    
                        this.showsnotify_warning("Страница будет доступна в день игры");
                        this.rlhdata['timer_reload_page']=3;
                        this.rlhdata['is_allow_reload_page']=true;
                    }
          
         
          
      },
      
         showsnotify_warning: function (text) {
          //notification methods
        this.$snotify.warning(text, {
          timeout: 3000,
          showProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
        });
      },
      
      
      
      to_profile: function () 
      {
   
              this.$router.push('/lkparent/profilep/'+'?t='+this.$root.add_v());
     
          
      },
      
      logout: function () 
      {
          //TEMP FOR PROTOTYPE
            this.$router.push('/'+'?t='+this.$root.add_v());
            
          /* WAR CODE
          console.log("logout");
         this.$root.logout();*/
      },
      
    toggleMobileSidebar: () => {
      document
        .querySelector(".bottom-navbar")
        .classList.toggle("header-toggled");
    },
    handleSCroll() {
      let header = document.querySelector("body");
      if (window.scrollY > 70) {
        header.classList.add("fixed-on-scroll");
      } else {
        header.classList.remove("fixed-on-scroll");
      }
    }
  },
  created() {
      
      window.addEventListener("scroll", this.handleSCroll);
      
   /* console.log("created app-header ~add by me!!!");
    
    
    
     console.log("Учитель? -"+this.$root.active_user['is_authed_teach']);
     console.log("Родитель? -"+this.$root.active_user['is_authed_parent']);
     console.log("Рребенок? -"+this.$root.active_user['is_authed_child']);*/
  },
  computed: {
     
       
       is_authed_parent () {
           
           if (this.$root.active_user['is_authed_parent'])
            {
                return true;
            }
           
           return false;
           
       },
       
        is_authed_child () {
           
            if (this.$root.active_user['is_authed_child'])
            {
                return true;
            }
           
           return false;
           
       },
       
       
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleSCroll);
  },
  watch: {
      
    $route() {
      document
        .querySelector(".bottom-navbar")
        .classList.remove("header-toggled");
    },
    
     

            "rlhdata.timer_reload_page": {
                handler(value) {

                    console.log('value='+value);

                    if (value > 0) {
                        setTimeout(() => {
                            this.rlhdata['timer_reload_page']--;
                        }, 1000);
                    }
                    else
                    {
                        
                        console.log('do reload');
                        if (this.rlhdata['is_allow_reload_page'])
                        {
                            this.rlhdata['is_allow_reload_page']=false;
                            window.location.reload(true);
                        }
                    }

                },
                immediate: true // This ensures the watcher is triggered upon creation
            }

          //The benefit of using this method is that the timer can be immediately reset by simply setting the value of timer_send_sms.
   
    
    
    
    
  }
};
</script>


<style scoped>
    
        
     
.menu_logos
{
   width:420px!important;
   max-width: unset!important;
}
.menu_logoexpo
{
width:100px!important;  
height: 45px!important; 
max-width: unset!important;
}
.menu_logomikebi
{
width:100px!important;  
height: 45px!important; 
max-width: unset!important;
}
.menu_pgrands
{
width:135px!important; 
height: 50px!important; 
max-width: unset!important;
}

 


@media (max-width: 992px) {
.menu_logos
{
   width:335px!important; 
   
}
.menu_logoexpo
{
width:78px!important; 
height: 35px!important;
max-width: unset!important;
}
.menu_logomikebi
{
width:78px!important; 
height: 35px!important;
max-width: unset!important;
}

/* 1040 388  */
.menu_pgrands
{
width:94px!important; 
height: 35px!important;
max-width: unset!important;
}


         
        

}




@media (max-width: 413px) {
.menu_logos
{
   width:235px!important; 
   
}
.menu_logoexpo
{
width:52px!important; 
height: 26px!important;
max-width: unset!important;
}
.menu_logomikebi
{
width:52px!important; 
height: 26px!important;
max-width: unset!important;
}

/* 1040 388  */
.menu_pgrands
{  
width:65px!important; 
height: 24px!important;
max-width: unset!important;
}


         
        

}





@media (max-width: 319px){
    
  .menu_menu_logoscontent{
    display: none; /*display: block;*/
}
  
}

 

    
</style>