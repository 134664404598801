<template>
  
</template>

<script>

var data = {
}

    
    
export default {
  name: "app-header",
  
  data () { //тюненгуемые переменные и функции
        return {
            rlhdata: data,    
        };
    },

  mounted() {
 
  },
  methods: {
      
    
  },
  computed: {
       
       
  },
  
   
  
  watch: {
   
    
  }
  
  
  
  
};
</script>

<style scoped>
    
    
        
    
</style>