import Vue from 'vue'
import App from './App.vue'
import router from './router'
import BootstrapVue from 'bootstrap-vue'
//import VueSweetalert2 from 'vue-sweetalert2'
import axios from 'axios';
import axiosRetry from 'axios-retry';

//метрика на eoevents@yandex.ru
import VueRouter from 'vue-router' /*?*/


import VueYandexMetrika from 'vue-yandex-metrika-ts' 
/*Vue.use(VueYandexMetrika, {
                id: 85997303,
                router: router,//this.$router,
              //scriptSrc: "https://mc.yandex.ru/metrika/tag_turboapp.js",
                env: process.env.NODE_ENV,   // PRODUCTION MODE if not set -DEV 
                options: {clickmap:true, trackLinks:true, accurateTrackBounce:true, trackHash:true } //?trackHash:true
            });*/
   //в коде на mounted 
   //if (this.$metrika)
   //this.$metrika.hit("rimo_start");
   //console.log(this.$metrika); //чё в объекте то из функций !!! там есть и друние  setUserID userParams для более крутой аналитики и сегментированя на основе импорта csv
   //this.$metrika.reachGoal('text_help_click');         
//конец метрика       



            
import VueMoment from 'vue-moment';
import moment from 'moment-timezone'; 
Vue.use(VueMoment, {
    moment,
})

//ЕСЛИ НАДО ПОДКЛЮЧИТЬ ВНЕШНИЙ JS ФАЙЛ!!!//import './uassets/js/jquery.inputmask.js'
//import './uassets/js/test.js' //НО ТУДА ПОЧЕМУ-ТО НЕ ПЕРЕДАЛСЯ $ - JQUAERY и я делал ON MOUNT там все работал, включал тут JQUARY //import $ from 'jquery'
const VueInputMask = require('vue-inputmask').default
Vue.use(VueInputMask)
Vue.use(BootstrapVue)
//Vue.use(VueSweetalert2)
//import LoadScript from 'vue-plugin-load-script';
//Vue.use(LoadScript)





/*
import Tawk from 'vue-tawk'
  
Vue.use(Tawk, {
    tawkSrc: 'https://embed.tawk.to/5f802263f0e7167d00177fdd/default'
})*/
 


Vue.config.productionTip = true
//Vue.config.silent = true  //??надо ли вообще.... 
//
//babel.config.js - отключение консоли на продакшн
//
//Vue.config.devtools = true  
//в   "rules": { 
// "no-console": "off",
//
 

  Vue.directive('focus', { //v-focus global user derictive
  // Когда привязанный элемент вставлен в DOM...
  inserted: function (el) {
    // Переключаем фокус на элемент
    el.focus()
  }
})

/* this.$root.rdata['aaa'] */ 
var data_json = {
   
    server_auth : "",  //set in  created()
    server_pay : "",  //set in  created()
    cloud_url : "",  //set in  created()
    password_for_decrypt: '', //set in  created()
     
    version:0,  /* this.$root.rdata['flags_uc'] */
    force_go_to_game : "",
    is_loaded_version : false,
    flags_uc: "",
    timings:"", 
    
    option_code__is_vip_presents: 203,    /* this.$root.rdata['option_code__is_vip_presents'] */   
    
    timer_reload__version_json: 30,
    timer_stage_changed: 5,
    timer_statdata: 120, /*not LESS axios execution 13 sec.......   120sec*/
    is_PG_logo :false, //лого президентского офнда
    
    
    tsel_gid : null,
    tsel_game_type : null,
    tsel_classnum : null,
     
    foo : 5, 
    message: 'Привет', 
}
var test_mode = {  //this.$root.test_mode['is_allow_lkchild_redirect']
    
    //ALLOW REDIRECT TO LKCHILD !!! чтобы пробрасывало в кабинет!!! ТОЛЬКО ПО КОМАНДЕ
    is_allow_lkchild_redirect:false, /*ЕСТЬ ЕЩЕ FORCE флаг в VERSION!!!*/
    is_allow_lkchild_redirect_test:false,
    
    //FUCK!!! ANSWERS!!! TEST AUTOCOMPILE!!! -> FALSE!!! FUCK
    is_allow_lkchild_answers_autocompile:false,
    
    
    //PARENT
    is_turn_on_reg_parent_def_values : true,
    is_turn_on_login_parent_def_values : false,
    
    //TEACH
    is_turn_on_reg_teach_def_values : false,
    is_turn_on_login_teach_def_values : false,
    
    //CHILD REGISTR
    is_turn_on_reg_child_def_values : false,
    
    //FORGOT
    is_turn_on_forgot_def_values : false,
    
    
    
    
    
}

/*
var step_select_game = {  
    gid:"",
    is_archive:"",
    game_type:"",
}*/

var step_shopvideo_data = {  
    linkvideo:"",
    name:"",
    info:"",
    backlink:"",
    }

var step_data = {   
   cjson_registration_parent_data:"",
   cjson_registration_teach_data:"",
   
   //lkchild_selected_lvl:1
}
var active_user = {     
     token:"",
     cjson_users: "",
     cjson_registrations:"",
     
     
     is_authed_teach: false, 
     is_authed_parent: false, 
     is_authed_child: false, 
     
   
}
var error_text = {  
     text_error_in_sending_data:"При отправке данных возникла ошибка: 'сервер недоступен'. Пожалуйста, проверьте ваш доступ к сети интернет и повторите попытку. ",
     text_error_execution_api: "При выполнении запроса возникла проблема. Мы уже занимаемся ее решением. Пожалуйста, повторите попытку позже. Приносим свои извинения за доставленные неудобства. ",
     }





var _gameObj={
  router,
  render: h => h(App),  
  data: {     
    script:null,
    rdata: data_json,   
    
    //данные передающиеся по шагам
    step_data: step_data, //данные передающиеся по шагам в регистрации
    
    //данные передающиеся при выборе игры
    //step_select_game: step_select_game,
    
    //флаги при тестировании
    test_mode:test_mode,    
    
    //авторизационная дата
    active_user: active_user,
   
   //ссылка на купленое видео
   step_shopvideo_data: step_shopvideo_data,
   
   error_text:error_text,
  }, 
  mounted() {
      
     //redirect from http to https
       /* if (location.protocol !== 'https:') {
            location.replace(`https:${location.href.substring(location.protocol.length)}`);
        }*/
      /*слетает на ошибку, если не на главную - но пока пофиг*/
      if(window.location.href.substr(0,5) !== 'https'){
        window.location.href = window.location.href.replace('http:', 'https:');
      }
      
      
       //!!Проверим, авторизован ли пользователь  СВЕРНУТЬ В ФУНКЦИЮ!!!
   
        this.is_authed();  //преверит целостность авторизационной строки  
     
        
     },
  created() {
       
 


// this.$root.rdata['server_pay']
 
     this.rdata['server_pay']="111https://payeo.website.yandexcloud.net";   //AUTH WAR
     this.rdata['cloud_domain']="qr.lvrc.ru";   //
      this.rdata['cloud_url']="https://qr.lvrc.ru";    
      this.rdata['img_cloud_url']="https://qr.lvrc.ru/";  
     
        this.rdata['server_auth']="https://io-back2.lvrc.ru/tapi.php";   //AUTH WAR
       
       
     
       
        this.rdata['password_for_decrypt']="freH4bg4t2"; 
        
        
        
        //LOCAL STORAGE EVENT CATCH - LOGOUT - Разлогин на отдной вкладке, должен приводить к разлогину на всех остальных вкладках!!!
         window.addEventListener('storage',this.onstorage);
         
         
        let is_allow_log;
        is_allow_log=true;
        axiosRetry(axios, 
                        { 
                            retries: 3, 
                            retryDelay: (retryCount) => {
                                let interval = 2000 * retryCount;
                                if (is_allow_log) {console.log(`Loader request failed. Attempt #${retryCount} will occur after ${interval}ms`);}
                                return interval;
                            }, retryCondition: (error) => {
                                if (is_allow_log) {console.log(error.response.status);}
                                //console.log(error);
                                //при любых ошибках - повторный запрос
                                return true;
                               // return axiosRetry.isNetworkOrIdempotentRequestError(error);// || error.response.status === 404 ;
                            }
                        }
                    );
        
  /*
window.onstorage = event => {
  if (event.key != 'now') return;
  alert(event.key + ':' + event.newValue + " at " + event.url);
};
      */
    
     
        
        
        
        
        
        
      this.rdata['foo']=this.rdata['foo']+1;
      console.log("CREATED main js ~add by me!!! +1 data="+this.rdata['foo']); 
      
  },
  computed: {
    computed_bar: function () { return "computed result"; }
  },
  methods: {
      
      
      
    
    
    
    
    
    
    
    
    
    
      
      
    to_execute_form_action: function (rldata) {      
    
     
     
      rldata['flagserror_common']['is_error_in_sending_data']=false;
      rldata['flagserror_common']['is_error_execution_api']=false;
        
      var url=this.$root.rdata['server_auth'];
                  
             var params = {
                  method:  'common__to_execute_load_data', 
                   data:  {
                     ticknum : (rldata['ticknum']===null?"":rldata['ticknum'].trim())
                  } 
              }; 
      
            axios.post(url, params )    
              .then(response => 
              {   
                 console.log(response['data']);  
                
                 switch(response['data']['answer_code'])
                       {
                           case "READY":
                                console.log("READY");
                           
                                rldata['ticknum']=response['data']['data']['ticknum'];  
                                
                                
                                rldata['flagserror_common']['is_error_execution_api']=false;
                                rldata['is_loaded_data']=true;
                                
                                
                                
                                
                               
                           break;
                          
                             case "TICKNUM_NOT_FIND":
                                rldata['flagserror_action']['is_error_ticknum_not_find']=true;
                            console.log("TICKNUM_NOT_FIND");
                           break; 
                               
                    default:   
                            //какая-то ошибка в API - не вернул обрабатываемый ответ
                             rldata['flagserror_common']['is_error_execution_api']=true;
                             rldata['flagserror_action']['check_smscode_button_disabled']=false; 
                           break;
                       }
                  //разрешаем кликать на кнопку для дейтсвия 
                   rldata['flags_logic']['is_act_disabled']=false;
                    
              })
            .catch(error => {
                    console.log(error);
                    //Флаг ошибки отправки данных
                    rldata['flagserror_common']['is_error_in_sending_data']=true;
                    rldata['flags_logic']['is_act_disabled']=false;
                    
            }).then(function () {  });
       
                
                
            
     
     
   
    
    
    },    
      
    
    
      
      
    get_data_by_objcode_and_loccode: function (rldata) {      
    
     
     
      rldata['flagserror_common']['is_error_in_sending_data']=false;
      rldata['flagserror_common']['is_error_execution_api']=false;
        
      var url=this.$root.rdata['server_auth'];
                  
             var params = {
                  method:  'common__f_load_data', 
                   data:  {
                     objcode : rldata['objcode'].trim(),
                     loccode : rldata['loccode'].trim(),
                     source: rldata['source'],
                  } 
              }; 
      
            axios.post(url, params )    
              .then(response => 
              {   
                 console.log(response['data']);  
                
                 switch(response['data']['answer_code'])
                       {
                           case "READY":
                                console.log("READY");
                           
                                rldata['r_tickets']=response['data']['data']['r_tickets'];
                                rldata['r_qrcodes']=response['data']['data']['r_qrcodes'];
                                rldata['r_objects']=response['data']['data']['r_objects'];  
                                rldata['client_location']=rldata['r_qrcodes']['qrinfo'];
                                
                                
                                rldata['flagserror_common']['is_error_execution_api']=false;
                                rldata['is_loaded_data']=true;
                                
                                
                                 console.log(response['data']['data']['r_objects']['objaudit']+"|"+response['data']['data']['r_objects']['objname']);  
                                
                               
                           break;
                          
                          /*  case "INCORRECT_CODE":
                                rldata['flagserror_action']['is_error_incorrect_code']=true;
                            console.log("INCORRECT_CODE");
                           break;*/
                               
                    default:   
                            //какая-то ошибка в API - не вернул обрабатываемый ответ
                             rldata['flagserror_common']['is_error_execution_api']=true;
                             rldata['flagserror_action']['check_smscode_button_disabled']=false; 
                           break;
                       }
                  //разрешаем кликать на кнопку для дейтсвия 
                   rldata['flags_logic']['is_act_disabled']=false;
                    
              })
            .catch(error => {
                    console.log(error);
                    //Флаг ошибки отправки данных
                    rldata['flagserror_common']['is_error_in_sending_data']=true;
                    rldata['flags_logic']['is_act_disabled']=false;
                    
            }).then(function () {  });
       
                
                
            
     
     
   
    
    
    },    
      
      
      
      
    get_tickets_by_objcode_for_10days: function (rldata) {      
    
     
     
      rldata['flagserror_common']['is_error_in_sending_data']=false;
      rldata['flagserror_common']['is_error_execution_api']=false;
        
      var url=this.$root.rdata['server_auth'];
                  
             var params = {
                  method:  'common__tlist_load_data', 
                   data:  {
                     objcode: rldata['objcode'].trim() 
                  } 
              }; 
      
            axios.post(url, params )    
              .then(response => 
              {   
                 console.log(response['data']);  
                
                 switch(response['data']['answer_code'])
                       {
                           case "READY":
                                console.log("READY");
                           
                                rldata['a_tickets']=response['data']['data']['a_tickets'];
                                rldata['is_loaded_tickets']=true;
                                rldata['flagserror_common']['is_error_execution_api']=false;
                               
                           break;
                          
                          /*  case "INCORRECT_CODE":
                                rldata['flagserror_action']['is_error_incorrect_code']=true;
                            console.log("INCORRECT_CODE");
                           break;*/
                               
                    default:   
                            //какая-то ошибка в API - не вернул обрабатываемый ответ
                             rldata['flagserror_common']['is_error_execution_api']=true;
                             //rldata['flagserror_action']['check_smscode_button_disabled']=false; 
                           break;
                       }
                  //разрешаем кликать на кнопку для дейтсвия 
                   rldata['flags_logic']['is_act_disabled']=false;
                    
              })
            .catch(error => {
                    console.log(error);
                    //Флаг ошибки отправки данных
                    rldata['flagserror_common']['is_error_in_sending_data']=true;
                    rldata['flags_logic']['is_act_disabled']=false;
                    
            }).then(function () {  });
       
                
                
            
     
     
   
    
    
    },    
      
      
    
    get_qrcodes_by_objcode: function (rldata) {      
    
     
     
      rldata['flagserror_common']['is_error_in_sending_data']=false;
      rldata['flagserror_common']['is_error_execution_api']=false;
        
      var url=this.$root.rdata['server_auth'];
                  
             var params = {
                  method:  'common__addqr_load_data', 
                   data:  {
                     objcode: rldata['objcode'].trim() 
                  } 
              }; 
      
            axios.post(url, params )    
              .then(response => 
              {   
                 console.log(response['data']);  
                
                 switch(response['data']['answer_code'])
                       {
                           case "READY":
                                console.log("READY");
                           
                                rldata['a_qrcodes']=response['data']['data']['a_qrcodes'];
                                rldata['is_loaded_qrcodes']=true;
                                rldata['flagserror_common']['is_error_execution_api']=false;
                               
                           break;
                          
                          /*  case "INCORRECT_CODE":
                                rldata['flagserror_action']['is_error_incorrect_code']=true;
                            console.log("INCORRECT_CODE");
                           break;*/
                               
                    default:   
                            //какая-то ошибка в API - не вернул обрабатываемый ответ
                             rldata['flagserror_common']['is_error_execution_api']=true;
                             rldata['flagserror_action']['check_smscode_button_disabled']=false; 
                           break;
                       }
                  //разрешаем кликать на кнопку для дейтсвия 
                   rldata['flags_logic']['is_act_disabled']=false;
                    
              })
            .catch(error => {
                    console.log(error);
                    //Флаг ошибки отправки данных
                    rldata['flagserror_common']['is_error_in_sending_data']=true;
                    rldata['flags_logic']['is_act_disabled']=false;
                    
            }).then(function () {  });
       
                
                
            
     
     
   
    
    
    },    
      
      
      
      
      
      
      
    
    read_user_lsdata: function (rldata) {      
    
    let cjson_qrcodes= localStorage.getItem('active_users[cjson_qrcodes]');      
    rldata['a_qrcodes'] = this.$root.decrypt_json(cjson_qrcodes);
    
    
    },  
      
      
      
      
      
  is_faild_ES6: function () {      
     
        
   try {
    //new Function("(a = 0) => a");
    //new Function("() => {};");
    
    
    //Object.keys(myObj).length;
    const myObject = new Object();
    myObject["firstname"] = "Gareth";
    myObject["lastname"] = "Simpson";
    myObject["age"] = 21;
    if (myObject.hasOwnProperty('firstname')){
        console.log("have property");
    }
    let obj_k_l=Object.keys(myObject).length;
    console.log("Object.keys(myObject).length="+obj_k_l);
    
    let xxr=Array.of(7);    
    console.log(xxr);       // [7]
    let xxe=Array.of(1, 2, 3);  
    console.log(xxe); // [1, 2, 3]
    
    let mmm = 2;
      
    const myFunction = num => {
        return num * 5;
    }
    let res=myFunction(mmm);
    console.log("myFunction(mmm) = "+res);
   
   const printName = (first, last) => console.log(`${first} ${last}`);
   printName("ES6","TEST");
   
 const fruits = ['apple', 'banana', 'grapes', 'mango', 'orange'];
  
/**
 * Элементы массива фильтруется на основе критериев поиска (query)
 */
const filterItems = (query) => {
  return fruits.filter((el) =>
    el.toLowerCase().indexOf(query.toLowerCase()) > -1
  );
}

let zz1=filterItems('ap');
let zz2=filterItems('an')
console.log(zz1); // ['apple', 'grapes']
console.log(zz2); // ['banana', 'mango', 'orange']
    
    
    return false;
  }
  catch (err) {
    return true;
  }
        
   
},
      
      
      
       declOfNum: function (number, titles) {  
        //   console.log("declOfNum call");
    var  cases = [2, 0, 1, 1, 1, 2];  
    return titles[ 
            (number % 100 > 4 && number % 100 < 20) 
            ? 
            2 
            : 
            cases[(number % 10 < 5) ? number % 10 : 5] 
    ];  
}, 
      
       
        set_stage_flags: function (rldata, delta_s, gid) {
            
            if (delta_s===null || delta_s==="") {delta_s=0;}
            
            
            let curr_timestamp=Math.floor(new Date().getTime()/1000.0)+parseInt(delta_s);
             
                                                       
                                    if (this.$root.rdata['timings'].hasOwnProperty(gid))
                                    {
                                       
                                        rldata['stages']['curr_timestamp']=curr_timestamp;
                                        rldata['stages']['delta_s']=parseInt(delta_s); 
                                        
                                        rldata['stages']['timestamp_utm_stage_end']=parseInt(this.$root.rdata['timings'][gid]['end']['timestamp_utc']);
                                        rldata['stages']['timestamp_utm_stage_shop']=parseInt(this.$root.rdata['timings'][gid]['shop']['timestamp_utc']);
                                        rldata['stages']['timestamp_utm_stage_transl2']=parseInt(this.$root.rdata['timings'][gid]['transl2']['timestamp_utc']);
                                        rldata['stages']['timestamp_utm_stage_start']=parseInt(this.$root.rdata['timings'][gid]['start']['timestamp_utc']);
                                        rldata['stages']['timestamp_utm_stage_tryplay']=parseInt(this.$root.rdata['timings'][gid]['tryplay']['timestamp_utc']);
                                        rldata['stages']['timestamp_utm_stage_transl']=parseInt(this.$root.rdata['timings'][gid]['transl']['timestamp_utc']);
                                        rldata['stages']['timestamp_utm_stage_pre']=parseInt(this.$root.rdata['timings'][gid]['pre']['timestamp_utc']);
                                        
                                        
                                        rldata['stages']['rtm_duration_befor_end']= parseInt(this.$root.rdata['timings'][gid]['end']['timestamp_utc']-curr_timestamp);
                                        rldata['stages']['rtm_duration_befor_shop']= parseInt(this.$root.rdata['timings'][gid]['shop']['timestamp_utc']-curr_timestamp);
                                        rldata['stages']['rtm_duration_befor_transl2']= parseInt(this.$root.rdata['timings'][gid]['transl2']['timestamp_utc']-curr_timestamp);
                                        rldata['stages']['rtm_duration_befor_start']= parseInt(this.$root.rdata['timings'][gid]['start']['timestamp_utc']-curr_timestamp);
                                        rldata['stages']['rtm_duration_befor_tryplay']= parseInt(this.$root.rdata['timings'][gid]['tryplay']['timestamp_utc']-curr_timestamp);
                                        rldata['stages']['rtm_duration_befor_transl']= parseInt(this.$root.rdata['timings'][gid]['transl']['timestamp_utc']-curr_timestamp);
                                        rldata['stages']['rtm_duration_befor_pre']= parseInt(this.$root.rdata['timings'][gid]['pre']['timestamp_utc']-curr_timestamp);
                                        
                                        
                                        
                                        //END STAGE
                                            if ( curr_timestamp>parseInt(this.$root.rdata['timings'][gid]['end']['timestamp_utc']) )
                                            {
                                                rldata['stages']['is_stage_end']=true;
                                                rldata['stages']['is_stage_shop']=false;
                                                rldata['stages']['is_stage_transl2']=false;
                                                rldata['stages']['is_stage_start']=false;
                                                rldata['stages']['is_stage_tryplay']=false;
                                                rldata['stages']['is_stage_transl']=false;
                                                rldata['stages']['is_stage_pre']=false; 
                                                return;
                                            }    
                                            
                                            //SHOP STAGE
                                            if ( 
                                                    curr_timestamp>parseInt(this.$root.rdata['timings'][gid]['shop']['timestamp_utc']) && 
                                                    curr_timestamp<=parseInt(this.$root.rdata['timings'][gid]['end']['timestamp_utc'])
                                                )
                                            {
                                                rldata['stages']['is_stage_end']=false;
                                                rldata['stages']['is_stage_shop']=true;
                                                rldata['stages']['is_stage_transl2']=false;
                                                rldata['stages']['is_stage_start']=false;
                                                rldata['stages']['is_stage_tryplay']=false;
                                                rldata['stages']['is_stage_transl']=false;
                                                rldata['stages']['is_stage_pre']=false; 
                                                return;
                                            }  
                                            
                                            
                                             //TRANSL2 STAGE
                                            if ( 
                                                    curr_timestamp>parseInt(this.$root.rdata['timings'][gid]['transl2']['timestamp_utc']) && 
                                                    curr_timestamp<=parseInt(this.$root.rdata['timings'][gid]['shop']['timestamp_utc'])
                                                )
                                            {
                                               
                                                rldata['stages']['is_stage_end']=false;
                                                rldata['stages']['is_stage_shop']=false;
                                                rldata['stages']['is_stage_transl2']=true;
                                                rldata['stages']['is_stage_start']=false;
                                                rldata['stages']['is_stage_tryplay']=false;
                                                rldata['stages']['is_stage_transl']=false;
                                                rldata['stages']['is_stage_pre']=false; 
                                                return;
                                            }  
                                            
                                            
                                            
                                            
                                            
                                            //START STAGE
                                           if ( 
                                                    curr_timestamp>parseInt(this.$root.rdata['timings'][gid]['start']['timestamp_utc']) && 
                                                    curr_timestamp<=parseInt(this.$root.rdata['timings'][gid]['transl2']['timestamp_utc'])
                                                )
                                            {
                                                rldata['stages']['is_stage_end']=false;
                                                rldata['stages']['is_stage_shop']=false;
                                                rldata['stages']['is_stage_transl2']=false;
                                                rldata['stages']['is_stage_start']=true;
                                                rldata['stages']['is_stage_tryplay']=false;
                                                rldata['stages']['is_stage_transl']=false;
                                                rldata['stages']['is_stage_pre']=false; 
                                                return;
                                            }  
                                            
                                            //START TRYPLAY
                                           if ( 
                                                    curr_timestamp>parseInt(this.$root.rdata['timings'][gid]['tryplay']['timestamp_utc']) && 
                                                    curr_timestamp<=parseInt(this.$root.rdata['timings'][gid]['start']['timestamp_utc'])
                                                )
                                            {
                                                rldata['stages']['is_stage_end']=false;
                                                rldata['stages']['is_stage_shop']=false;
                                                rldata['stages']['is_stage_transl2']=false;
                                                rldata['stages']['is_stage_start']=false;
                                                rldata['stages']['is_stage_tryplay']=true;
                                                rldata['stages']['is_stage_transl']=false;
                                                rldata['stages']['is_stage_pre']=false; 
                                                return;
                                            }    
                                                
                                                
                                             //START TRANSL
                                           if ( 
                                                    curr_timestamp>parseInt(this.$root.rdata['timings'][gid]['transl']['timestamp_utc']) && 
                                                    curr_timestamp<=parseInt(this.$root.rdata['timings'][gid]['tryplay']['timestamp_utc'])
                                                )
                                            {
                                                rldata['stages']['is_stage_end']=false;
                                                rldata['stages']['is_stage_shop']=false;
                                                rldata['stages']['is_stage_transl2']=false;
                                                rldata['stages']['is_stage_start']=false;
                                                rldata['stages']['is_stage_tryplay']=false;
                                                rldata['stages']['is_stage_transl']=true;
                                                rldata['stages']['is_stage_pre']=false; 
                                                return;
                                            }    
                                            
                                            
                                             //START PRE   всегда PRE до старта TRANSL timing
                                           if ( 
                                                  /*  curr_timestamp>parseInt(this.$root.rdata['timings'][gid]['pre']['timestamp_utc']) && */
                                                    curr_timestamp<=parseInt(this.$root.rdata['timings'][gid]['transl']['timestamp_utc'])
                                                            
                                                )
                                            {
                                                rldata['stages']['is_stage_end']=false;
                                                rldata['stages']['is_stage_shop']=false;
                                                rldata['stages']['is_stage_transl2']=false;
                                                rldata['stages']['is_stage_start']=false;
                                                rldata['stages']['is_stage_tryplay']=false;
                                                rldata['stages']['is_stage_transl']=false;
                                                rldata['stages']['is_stage_pre']=true; 
                                                return;
                                            }      
                                                
                                        
                                        //OTHERCASE - ERROR
                                                rldata['stages']['is_stage_end']=false;
                                                rldata['stages']['is_stage_shop']=false;
                                                rldata['stages']['is_stage_transl2']=false;
                                                rldata['stages']['is_stage_start']=false;
                                                rldata['stages']['is_stage_tryplay']=false;
                                                rldata['stages']['is_stage_transl']=false;
                                                rldata['stages']['is_stage_pre']=false;    
                                        
                                        
                                        
                                        
                                      
                        
                        
                                    }else{console.log("NO PROPERTY this.$root.rdata['timings'].hasOwnProperty(gid) - look version.json");}
                           
            
            
        },
      
       
    clear_tel: function (tel) {
          let ctel= tel.replace(/[^0-9]/g, '');
          //если номер прилетел с 7-йкой или 8-кой подкоротим его до 10-ть значного формата 9210001122
          if(ctel!="" && ctel.length==11)
          {
              ctel=ctel.substring(1);
          }
          return ctel;
      },
    
    
    
       load_chat_lkchild: function (rldata) {
        
           try {
 
       

            let url=location.protocol+'//'+window.location.hostname+(location.port ? ':'+location.port: '')+"/chat.js?f="+this.$root.add_v();
            console.log(url);
            
            
            
            
               
         
            
            
        
            this.$loadScript(url)
            .then(() => {
               console.log('Tawk_API ~ SCRIPT IN');
       

       
     //  console.log(Tawk_API);
       
       
                    Tawk_API.onLoad = function(){
                    console.log("Tawk_API ~ ONLOAD IN");

                //        Tawk_API.showWidget();
 
            
                    Tawk_API.setAttributes({
                     classnum : rldata['a_registrations']['classnum'],
                     name : rldata['a_registrations']['child_name']+" "+rldata['a_registrations']['child_surname']+" (класс "+rldata['a_registrations']['classnum']+")",
                     email : rldata['a_registrations']['parent_email'] 
                               }, function(error){});

                        

                        let lvl= localStorage.getItem('lkchild_last_selected_lvl');
                        if (lvl!==null )
                        {
                            
                            console.log("Tawk_API UP");
                                 Tawk_API.setAttributes({
                                       lvl    :  lvl 
                                   }, function(error){});
                           }
                           else {console.log("Tawk_API lvl load fail - no lkchild_last_selected_lvl in ls");}   
                            
                         

                    }     
               
                console.log('Tawk_API SCRIPT FIN');

            })
            .catch((error2) => {
               console.log('Tawk_API Teacher_parent fail start chat:'+error2);
            });
            
            
            if (window.hasOwnProperty('Tawk_API')){ 
                
                                        Tawk_API.setAttributes({
                                         classnum : rldata['a_registrations']['classnum'],
                                         name : rldata['a_registrations']['child_name']+" "+rldata['a_registrations']['child_surname']+" (класс "+rldata['a_registrations']['classnum']+")",
                                         email : rldata['a_registrations']['parent_email'] 
                                                   }, function(error){});
                    
                     
                     
                                                    let lvl= localStorage.getItem('lkchild_last_selected_lvl');
                                                      if (lvl!==null )
                                                      {
                                                               Tawk_API.setAttributes({
                                                                     lvl    :  lvl 
                                                                 }, function(error){});
                                                         }else {console.log("Tawk lvl load fail - no lkchild_last_selected_lvl in ls");}              

                     
                     } 
                     
            
                     
                     
                     
                     
            

            //!!!!!!!
           //Tawk_API.showWidget();   
           //Tawk_API.hideWidget();               
              
}
catch (e) { 
   console.log(e);
   
     console.log("Tawk_API ~ FAILD ONLOAD");
    
   
}   

       },
       
      
         load_chat_parent_or_teacher: function (rldata) {
             
try {   



             let url=location.protocol+'//'+window.location.hostname+(location.port ? ':'+location.port: '')+"/chat.js?f="+this.$root.add_v();
            console.log(url);
            
            
            
            this.$loadScript(url)
            .then(() => {
               console.log('Tawk_API Teacher_parent IN');
       
                    Tawk_API.onLoad = function(){

            //    Tawk_API.showWidget();

/*
                             Tawk_API.visitor = {
                     name : rldata['a_registrations']['child_name']+" "+rldata['a_registrations']['child_surname']+" (класс "+rldata['a_registrations']['classnum']+")",
                     email : rldata['a_registrations']['parent_email'] 
                     };*/

                    Tawk_API.setAttributes({
                     classnum : 0,
                     name : rldata['user_name']+" "+rldata['user_patronymic']+" "+rldata['user_surname'],
                     email : rldata['email'] 
                               }, function(error){});

                          /*   Tawk_API.setAttributes({
                                   classnum : rldata['classnum']
                               }, function(error){});*/


                           if (rldata.hasOwnProperty('lvl')) {
                                         Tawk_API.setAttributes({
                                      lvl    : rldata['lvl'] 
                                  }, function(error){});
                            }else {console.log("Tawk lvl load fail - no rldata['lvl']");}

                    }     
               
                console.log('Tawk_API Teacher_parent FIN');

            })
            .catch((error2) => {
               console.log('Tawk_API Teacher_parent fail start chat:'+error2);
            });
            
            
            if (window.hasOwnProperty('Tawk_API')){ 
                                        Tawk_API.setAttributes({
                                         classnum : rldata['classnum'],
                                         name : rldata['user_name']+" "+rldata['user_patronymic']+" "+rldata['user_surname'],
                                         email : rldata['email'] 
                                                   }, function(error){});
                     }

            //!!!!!!!
           //Tawk_API.showWidget();   
           //Tawk_API.hideWidget();
           
       }
catch (e) { 
   console.log(e);
}       
           
           
       },
       
    
         
   cnt_ol_spend: function  (rldata){
        
        rldata['ol_spend']=0;
     
        for(var i=0; i<rldata['prodback']['a_prodback'].length; i++ )
        { 
             rldata['ol_spend']=rldata['ol_spend']+parseInt(rldata['prodback']['a_prodback'][i]['price']);
         }
        
   },
        
        
   cnt_ol_summ: function  (rldata){
        
        rldata['ol_summ']=0;
     
        for(var i=0; i<rldata['sendback']['a_sendback'].length; i++ )
        {
           // console.log(i+"|=i");// ||| cnt_fails="+rldata['sendback']['a_sendback'][i]['cnt_fails'].toString());
            //IF IN TIME LINE cnt_fails
            if (rldata['sendback']['a_sendback'][i]['cnt_fails'].toString()==="0"){
             rldata['ol_summ']=rldata['ol_summ']+parseInt(rldata['sendback']['a_sendback'][i]['lvl']);
            } 
        }
        
   },
   
    calc_winners: function  (classnum){
        let max;
         switch(classnum)
          {
              case "1":  max=6; break;
              case "2":  max=2; break;     
              case "3":  max=3; break;    
              case "4":  max=2; break; 
              case "5":  max=2; break;
              case "6":  max=2; break;
          }
        return max;
    },
   
   
   
   load_ls_onlinetimings_list: function  (rldata){
        
        let cjson_onlinetimings=localStorage.getItem('onlinetimings');
        if (cjson_onlinetimings!==null){
            rldata['onlinetimings']=this.$root.decrypt_json(cjson_onlinetimings);
            return true;
        } 
        
       return false; 
    },     
        
   
   
   
   load_ls_onlinegames_list: function  (rldata){
        
        let cjson_onlinegames=localStorage.getItem('onlinegames');
        if (cjson_onlinegames!==null){
            rldata['onlinegames']=this.$root.decrypt_json(cjson_onlinegames);
            return true;
        } 
        
       return false; 
    },     
        
     
     /*
    load_ls_school_list: function  (rldata){
        
        let cjson_school_lo=localStorage.getItem('school_lo');
        if (cjson_school_lo!==null){
            rldata['school_lo']=this.$root.decrypt_json(cjson_school_lo);
            return true;
        } 
        
       return false; 
    },*/
        
        
    load_ls_registrations: function  (rldata){
        
                 let cjson_registrations= localStorage.getItem('active_child[cjson_registrations]'); 
                 if (cjson_registrations!==null)
                 { 
                        rldata['a_registrations']=this.$root.decrypt_json(cjson_registrations); 
                        return true;   
                }
                
       return false; 
    }, 
    
    
     load_ls_task: function  (rldata){
        
          //TASK
                let gid=rldata['a_registrations']['gid'];  
                if (localStorage.getItem('task')!==null)
                {
                    let cjson_task=localStorage.getItem('task');
                    rldata['task']=this.$root.decrypt_json(cjson_task);
                    if (rldata['task'].hasOwnProperty('gid')){
                        if(rldata['task']['gid']===gid)  //DA CHEKAET OTHER GAME TASKS!
                        {
                            //ONLY LS CSENARIO
                            this.$root.get_task_data(rldata,gid); // будет помещено в this.rldata['task'],   если нету в ls подтянет из json и по факту загрузки обновит данные
                            return true;
                        }
                        else
                        {
                            console.log("!!! rldata['task']['gid']!==gid");
                        }
                    }
                    else
                        {
                            console.log("NO PROPETY rldata['task'].hasOwnProperty('gid')");
                        }
                }
                else
                        {
                            console.log("NULL localStorage.getItem('task')");
                        }
                
       return false;
    }, 
    
    
    
    load_ls_pricekoef: function  (rldata){
        
          //pricekoef
                let gid=rldata['a_registrations']['gid'];  
                if (localStorage.getItem('pricekoef')!==null)
                {
                    let cjson_pricekoef=localStorage.getItem('pricekoef');
                    rldata['pricekoef']=this.$root.decrypt_json(cjson_pricekoef);
                    if (rldata['pricekoef'].hasOwnProperty('gid')){
                        if(rldata['pricekoef']['gid']===gid)  //DA CHEKAET OTHER GAME pricekoefS!
                        {
                            //ONLY LS CSENARIO
                            this.$root.get_pricekoef_data(rldata,gid); // будет помещено в this.rldata['pricekoef'],   если нету в ls подтянет из json и по факту загрузки обновит данные
                            return true;
                        }
                        else
                        {
                            console.log("!!! NOT EQUAL rldata['pricekoef']['gid']===gid");
                        }
                    }
                    else
                        {
                            console.log("!!! NOT PROPETY rldata['pricekoef'].hasOwnProperty('gid')");
                        }
                }
                else
                        {
                            console.log("!!! NULL localStorage.getItem('pricekoef')!==null");
                        }
                
       return false;
    }, 
    
    
    
    
    
    
      load_ls_shop: function  (rldata){
        
          //shop
                let gid=rldata['a_registrations']['gid'];  
                if (localStorage.getItem('shop')!==null)
                {
                    let cjson_shop=localStorage.getItem('shop');
                    rldata['shop']=this.$root.decrypt_json(cjson_shop);
                    if (rldata['shop'].hasOwnProperty('gid')){
                        if(rldata['shop']['gid']===gid)  //DA CHEKAET OTHER GAME shopS!
                        {
                            //ONLY LS CSENARIO
                            this.$root.get_shop_data(rldata,gid); // будет помещено в this.rldata['shop'],   если нету в ls подтянет из json и по факту загрузки обновит данные
                            
                            return true;
                        }
                        else
                        {
                            console.log("!!! NOT EQUAL rldata['shop']['gid']===gid");
                        }
                    }
                    else
                        {
                            console.log("NO PROPETY rldata['shop'].hasOwnProperty('gid')");
                        }
                }
                 else
                        {
                            console.log("IS NULL localStorage.getItem('shop')!==null");
                        }
                
       return false;
    }, 
    
    
    
    
    
      load_ls_prodback: function  (rldata){
        
          //prodback
                let rid=rldata['a_registrations']['rid']; 
                let gid=rldata['a_registrations']['gid']; 
                if (localStorage.getItem('prodback')!==null)
                {
                    let cjson_prodback=localStorage.getItem('prodback');
                    rldata['prodback']=this.$root.decrypt_json(cjson_prodback);
                    if (rldata['prodback'].hasOwnProperty('rid') && rldata['prodback'].hasOwnProperty('gid')){
                        if(rldata['prodback']['rid']===rid && rldata['prodback']['gid']===gid)  //DA CHEKAET OTHER prodback!
                        {
                                            //ONLY LS CSENARIO
                                            this.$root.get_prodback_data(rldata,rid,gid);  
                                            return true;
                        }
                    } 
                }
                
       return false;
    },   
    
    
    
    
    
    
     
      load_ls_sendback: function  (rldata){
        
          //SENDBACK
                let rid=rldata['a_registrations']['rid'];  
                let gid=rldata['a_registrations']['gid']; 
                if (localStorage.getItem('sendback')!==null)
                {
                    let cjson_sendback=localStorage.getItem('sendback');
                    rldata['sendback']=this.$root.decrypt_json(cjson_sendback);
                     if (rldata['sendback'].hasOwnProperty('rid') && rldata['sendback'].hasOwnProperty('gid')){
                        if(rldata['sendback']['rid']===rid && rldata['sendback']['gid']===gid)  //DA CHEKAET OTHER sendback!
                        {
                            //ONLY LS CSENARIO
                            this.$root.get_sendback_data(rldata,rid,gid);  
                            return true;
                        }
                        else
                        {
                            console.log("NOT EQUAL rldata['sendback']['rid']===rid && rldata['sendback']['gid']===gid");
                        }
                        
                    } 
                    else
                    {
                        console.log("NO PROPERTY rldata['prodback'].hasOwnProperty('rid') && rldata['prodback'].hasOwnProperty('gid')");
                    }

                }
                else
                {
                    console.log("NULL localStorage.getItem('sendback')!==null");
                }
                
       return false;
    },   
    
       load_ls_achives: function  (rldata){
        
          //achives
                let rid=rldata['a_registrations']['rid'];  
                if (localStorage.getItem('achives')!==null)
                {
                    let cjson_achives=localStorage.getItem('achives');
                    rldata['achives']=this.$root.decrypt_json(cjson_achives);
                    
                    if (rldata['achives'].hasOwnProperty('rid')){
                        if(rldata['achives']['rid']===rid)  //DA CHEKAET OTHER achives!
                        {
                            //ONLY LS CSENARIO
                            this.$root.get_achives_data(rldata,rid);  
                            return true;
                        }
                    }
                    else
                    {
                        localStorage.removeItem('achives');
                    }
                    
                }
                
       return false;
    },   
      
  is_authed: function (){
      
       var is_fail=false;
    
           try {
               
               
                var  cjson_users= localStorage.getItem('active_users[cjson_users]');
          
                if ( !(cjson_users===null) )
                {
                    
                    this.active_user['cjson_users']=cjson_users;
                   
                    let a_users=this.$root.decrypt_json(cjson_users);
                    
             
                    console.log("+++");
                    console.log(a_users);
                   
                }
                else
                {
                    is_fail=true;
                }
            
              } catch(e) {
                  console.log("error---");
                  console.log(e);
                  is_fail=true;
              }
              
              if (is_fail)
              {  
                  this.$root.data_clear_user();
                  return false;
              }
      
      return true;
  },    
      
  logout: function (){
    
     console.log("DO CLEAR");
      
     
     this.$root.data_clear_user();
     
     //this.$router.push('/landing/login_2');
      window.location.href='/landing/login_2'+'?t='+this.$root.add_v();
      
  },
  
   
    //Разлогин на отдной вкладке, должен приводить к разлогину на всех остальных вкладках!!!  
    onstorage : function () {
    //console.log(event.key + ':' + event.newValue + " at " + event.url);
     
    
    switch (event.key)
    {
        case 'active_users[token]':
            
                    console.log(event.key+"|event.key="+event.newValue);


                    if (event.newValue===null || event.newValue==="")
                    {
                        console.log("DO CLEAR");
                        this.$root.data_clear_user();
                    }

                    //если оно в зоне lkteacher или lkparent?
                    if ( !(window.location.pathname.toLowerCase().indexOf("action")>-1 || window.location.pathname.toLowerCase().indexOf("lkchild")>-1 || window.location.pathname.toLowerCase()==="/landing/dummy") )
                        {
                            console.log('window.location.reload');
                            window.location.reload();
                            
                        }
            
            break;
            
            
         case '_child_rguidlink':
             
               console.log("event.newValeu="+event.newValue+" | event.url="+event.url);
               console.log(window.location.pathname);
               console.log(window.location.pathname.toLowerCase().indexOf("action"));
               console.log(window.location.pathname.toLowerCase().indexOf("lkchild"));
               console.log(window.location.pathname.toLowerCase()==="/landing/dummy");
               
               
               //если оно в зоне action или lkchild или dummy              
               if (window.location.pathname.toLowerCase().indexOf("action")>-1 || window.location.pathname.toLowerCase().indexOf("lkchild")>-1 || window.location.pathname.toLowerCase()==="/landing/dummy")
               {
                   window.location.href=window.location.protocol+'//'+window.location.hostname+(location.port ? ':'+location.port: '')+'/landing/dummy?rguidlink='+event.newValue;
               }
               
               //если она равна window.location.pathname!=="/landing/dummy"
               //то
            
            break;   
           
            
    }/*end switch*/
     
       
       
 },
    data_clear_user: function () { 
        
              
                localStorage.removeItem('active_users[cjson_users]');
                localStorage.removeItem('active_childs[cjson_childs]');
                localStorage.removeItem('active_subjects[cjson_subjects]');
                localStorage.removeItem('active_games[cjson_games]');
                 
        
    },
    
    //ребенок - это фактически регистрация
    data_clear_child: function () { 
               localStorage.removeItem('active_child[cjson_registrations]');
               this.active_user['cjson_users']="";
    },
    
   
   encrypt_string: function (str) { 
   
        var crypto = require('crypto');
        var mykey = crypto.createCipher('aes-128-cbc', this.rdata['password_for_decrypt']);
        var mystr = mykey.update(str, 'utf8', 'hex')
        mystr += mykey.final('hex');
    
        return mystr;
   },
   
    decrypt_string: function (cstr) { 
        
        var crypto = require('crypto');
        var mykey = crypto.createDecipher('aes-128-cbc', this.rdata['password_for_decrypt']);
        var mystr = mykey.update(cstr, 'hex', 'utf8')
        mystr += mykey.final('utf8');  
         
       return mystr;
    },
    
   
     
    encrypt_json: function (json_data) { 
        
        var str= JSON.stringify(json_data);
        var crypto = require('crypto');
        var mykey = crypto.createCipher('aes-128-cbc', this.rdata['password_for_decrypt']);
        var mystr = mykey.update(str, 'utf8', 'hex')
        mystr += mykey.final('hex');
    
        return mystr;
    },
    
    
    decrypt_json: function (cjson_data) { 
        
        var crypto = require('crypto');
        var mykey = crypto.createDecipher('aes-128-cbc', this.rdata['password_for_decrypt']);
        var mystr = mykey.update(cjson_data, 'hex', 'utf8')
        mystr += mykey.final('utf8');  
        var json=JSON.parse(mystr);
       
       return json;
    },
    
    
     getlink_by_key: function (key) { 
         switch (key)
         {
             
             
             
             case "download_presentation":    return '/games_data/dash/%D0%90%D1%80%D0%B8%D1%84%D0%BC%D0%B5%D1%82%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%B8%D0%B5_%D0%98%D0%B3%D1%80%D1%8B_ExpoOlimpia_online.pdf'     //return '/games_data/dash/%D0%90%D1%80%D0%B8%D1%84%D0%BC%D0%B5%D1%82%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%B8%D0%B5%20%D0%98%D0%B3%D1%80%D1%8B%20ExpoOlimpia%20%D0%B4%D0%BB%D1%8F%20%D0%9B%D0%B5%D0%BD%D0%B8%D0%BD%D0%B3%D1%80%D0%B0%D0%B4%D1%81%D0%BA%D0%BE%D0%B9%20%D0%BE%D0%B1%D0%BB%D0%B0%D1%81%D1%82%D0%B8.pdf';
                 break;
             case "download_rule":  return '/games_data/dash/rules600_3.pdf';
                 break;
             
             default: return "нет ключа"; 
                 
         }
         
     },
    
    create_twitch_obj_and_add_to_div: function (rldata,twitch_obj,twi_div,is_load_flag) { 
    
                            rldata[is_load_flag]=false;
                                this.$loadScript("https://player.twitch.tv/js/embed/v1.js")
                                .then(() => {
                                    rldata[is_load_flag]=true;
                                        var options = {
                                         /*allowfullscreen: false,*/
                                         width: '100%',
                                         height: '100%',
                                         //channel: "memdic007", 
                                         channel: "expoolimpia", 
                                         parent: ["f0-dev.eo.events", window.location.hostname.toString() ], 
                                         // only needed if your site is also embedded on embed.example.com and othersite.example.com
                                         //parent: ["f0.eo.events", "eo.events","f0-dev.eo.events","eoevents.website.yandexcloud.net"],     //[window.location.hostname],
                                       };
                                       //console.log('HERE ...');
                                       //console.log("twitch_obj= ниже");
                                       //console.log(rldata[twitch_obj]);
                                        if (rldata[twitch_obj]===null)
                                        {
                                            //document.getElementById(twi_div).innerHTML="";
                                            console.log('HERE2 created twich');
                                            rldata[twitch_obj] = new Twitch.Player(twi_div, options);
                                        }
                                  })
                                .catch((ex) => {
                                   console.log('fail twich='+ex);
                                });  
                                
                                rldata[is_load_flag]=true;
    },
    
    
     
    
    
     clear_localstorage_on_updt: function (localstorage_stamp) {
         
       
        if (localstorage_stamp!==null && localstorage_stamp!=="") 
        {
                    /* СБРОС LOCALSTORAGE на обновление  */
                 var ls_v=localStorage.getItem(localstorage_stamp);
                 if (ls_v===null)
                 {
                     let is_allow_EOO2 = localStorage.getItem('is_allow_EOO2');
                     let teachguid = localStorage.getItem('teachguid');
                     let teachschid = localStorage.getItem('teachschid');
                     localStorage.clear();


                     localStorage.setItem(localstorage_stamp,true);
                     if (is_allow_EOO2!==null)
                       {
                           localStorage.setItem('is_allow_EOO2','true');
                       }
                     if (teachguid!==null)
                       {
                           localStorage.setItem('teachguid', teachguid);
                       }
                     if (teachschid!==null)
                       {
                           localStorage.setItem('teachschid', teachschid);
                       }
                 }
                 
        }     
                 
      /* СБРОС LOCALSTORAGE на обновление  */
         
     },
     
     clear_localstorage_for_DUMMY: function () {
         
         /*CLEAR UPDATEBLE DATA FOR GAME if FLAG!*/ 
           
                                localStorage.removeItem('active_child[cjson_registrations]');
                                localStorage.removeItem('onlinegames');
                                localStorage.removeItem('onlinetimings');
                                localStorage.removeItem('school_lo');
                                localStorage.removeItem('sendback');
                                localStorage.removeItem('task');

                                localStorage.removeItem('shop');
                                localStorage.removeItem('pricekoef');
                                localStorage.removeItem('prodback');

                                localStorage.removeItem('achives');
                      
     },
    
    
 add_v:   function () {   
    
    
    
     let cjson_version_timestamp_utc = localStorage.getItem('version_timestamp_utc');
     if (cjson_version_timestamp_utc===null)
     {
         let ts=Math.floor(new Date().getTime()/1000.0);
         return (ts.toString());//+"_");
     }
     else
     {
         let json_version_timestamp_utc=this.$root.decrypt_json(cjson_version_timestamp_utc);
         return json_version_timestamp_utc['version_timestamp_utc'];
     }
      
 },
    
    
    
    
    
    check_version: function (rldata,is_update_page_version) {
      this.$root.rdata['is_loaded_version']=false;
      
      let is_allow_log=true;
      
       
      
      //##!!!TIME IN JSON AND IN JAVASCRIPT IN UTC!!!##
      //let result=false;
         
            
             
        let url=location.protocol+'//'+window.location.hostname+(location.port ? ':'+location.port: '')+'/version.json'+'?timestamp='+(new Date()).getTime();
        const config = {
            headers: { Pragma: 'no-cache'} 
        }  
        axios.get(url, config)      
              .then(response => 
              {  
                  
                   try{ 
                      //  console.log(response);
                        //ПОЛУЧИЛИ данные из version.json
                        if (is_allow_log){console.log("version="+response['data']['version_timestamp_utc']);}
                        let settingsjson_version_timestamp_utc= response['data']['version_timestamp_utc'];
                        
                        console.log("VERSION IS "+ response['data']['version_timestamp_utc']);
                        
                        this.$root.rdata['version']=response['data']['version_timestamp_utc'];
                        this.$root.rdata['force_go_to_game']=response['data']['force_go_to_game'];
                        this.$root.rdata['flags_uc']=response['data']['flags_uc'];
                        this.$root.rdata['timings']=response['data']['timings'];
                        //console.log(this.$root.rdata['timings']);
                        //console.log(response['data']['timings']);
                        this.$root.rdata['is_loaded_version']=true;
                        if (rldata.hasOwnProperty('cnt_loaded'))
                        {
                            rldata['cnt_loaded']=rldata['cnt_loaded']+1;
                        }
                        
                        

                        //получим данные из local storage (зашифрованы) - если нету - то поставим 0
                        let ls_version_timestamp_utc=0;
                        let cjson_version_timestamp_utc = localStorage.getItem('version_timestamp_utc');
                        
                        if (is_allow_log){console.log("cjson_version_timestamp_utc="+cjson_version_timestamp_utc);}
                        
                        
                        if (cjson_version_timestamp_utc!==null)
                        {
                            //расшифруем
                            //console.log('delit');
                            let json_version_timestamp_utc=this.$root.decrypt_json(cjson_version_timestamp_utc);
                            ls_version_timestamp_utc=json_version_timestamp_utc['version_timestamp_utc'];
                            if (is_allow_log){console.log("ls_version_timestamp_utc="+ls_version_timestamp_utc);}
                        }
                        
                       
                        if (is_allow_log){console.log("ls="+ls_version_timestamp_utc+"|json="+settingsjson_version_timestamp_utc);}
                        let diff_in_sec_ls_minus_json=(ls_version_timestamp_utc-settingsjson_version_timestamp_utc);
                        if (is_allow_log){console.log("version LC-JSON="+ diff_in_sec_ls_minus_json +" sec | ~"+ diff_in_sec_ls_minus_json/60 +" min " );}

                           
                        //если есть расхождение в версии или файла с ферсией в ls нет вообще 
                        if (diff_in_sec_ls_minus_json<0)
                        {
                           
                             /*  Сохраним свежую версию в ls*/
                           var newls_version_timestamp_utc = {};
                           newls_version_timestamp_utc['version_timestamp_utc']=settingsjson_version_timestamp_utc;
                           var newcjson_version_timestamp_utc=this.$root.encrypt_json(newls_version_timestamp_utc);
                           localStorage.setItem('version_timestamp_utc', newcjson_version_timestamp_utc );
                           
                           if (response['data'].hasOwnProperty('version_localstorage')){
                               this.clear_localstorage_on_updt(response['data']['version_localstorage']);//временная функция до проведения 2-х грантовых игр
                            } 
                            
                    
                        }
                        else
                        {
                           console.log("DUMMY VERSION CORRECT - NOTHING TODO");
                          
                          
                          //проверим если users есть, а ctel нет.... // то логаут
                         }
                         
                         if (is_update_page_version)
                         {
                             //обновим page_version
                             this.$root.page_version_update_and_reload();
                         }
                
                  
                  } catch(e) {
                      
                      //если какая-то ошибка - просто снесем значение в local storage
                      localStorage.removeItem('version_timestamp_utc');
                      
                      
                      console.log("error---");
                      console.log(e);
                      
                      //ничего делать не надо                      
                    }
              
               
              })
            .catch(error => {
                console.log("CHECK VERSION, all attemps faild ="+error);
                //ROUTE TO PAGE WITH RELOAD BUTTON AND CALLBACK
            })
             .then(function () {
              /*  console.log("always exec");
                console.log("result="+result);
                if (result===false)
                {
                    console.log("reload");
                }*/
                
            });
      
      
      
      
      
      
      
        
    },
    
    
    
     
    
    
    
    //
    //curr_event_code
    //
    //
    
    //ОБНОВЛЯЕТ ВЕРСИЮ СТРАНИЦЫ В СООТВЕТСТВИИ С СОХРАНЕННОЙ ВЕРСИЕЙ В ЛОКАЛ СТОРАДЖ
     page_version_update_and_reload: function () {
         console.log("CHECK PAGE:"+window.location.pathname.replace(/\//g, ''));
         
         //!!! НЕ ВКЛЮЧАТЬ this.clear_localstorage_on_updt_20210330("__updt_20210403_1");//временная функция до проведения 2-х грантовых игр
         
         let cjson_version_timestamp_utc=localStorage.getItem('version_timestamp_utc');
         if (cjson_version_timestamp_utc!==null)
         {
             
         let version_timestamp_utc= this.$root.decrypt_json(cjson_version_timestamp_utc);
          
         
         let curr_ver=version_timestamp_utc['version_timestamp_utc'];      
         console.log("CURR SITE VERSION:"+version_timestamp_utc['version_timestamp_utc']);
         
             //НЕ ВКЛЮЧАТЬ - ТОЛЬКО ДЛЯ ПРОВЕРКИ добавления к URL  t=time()
             //this.$root.reload_page_with_t_stamp(curr_ver);
         
                                    
                                    //if (is_allow_log){console.log("nothing to do");}
                                    //если в версии кэша страницы не совпадает версия на сервере в version.json то страницу надо обновить.
                                    let version_chach_page=localStorage.getItem('version_chach_page['+window.location.pathname.replace(/\//g, '')+']');
                                    if (version_chach_page===null)
                                    { 
                                        console.log("PAGE_VERSION IS NULL |set="+curr_ver);
                                        localStorage.setItem('version_chach_page['+window.location.pathname.replace(/\//g, '')+']', curr_ver );
                                        
                                        //перезагрузка!  тоже сделаем! 
                                        //this.$root.reload_page_with_t_stamp(curr_ver);
                                        window.location.reload();  
                                         
                                    }
                                    else{
                                                 
                                                console.log("PAGE_VERSION NOT NULL AND is="+version_chach_page);
                                                console.log("ACTUAL VERSION site is="+curr_ver);
                                                if (version_chach_page!==curr_ver)
                                                { 
                                                     console.log("UPDATE PAGE_VERSION TO="+curr_ver); 
                                                     localStorage.setItem('version_chach_page['+window.location.pathname.replace(/\//g, '')+']', curr_ver );
                                                      //перезагрузка!
                                                      //window.location.reload();  
                                                       //this.$root.reload_page_with_t_stamp(curr_ver);
                                                       window.location.reload();  
                                                }
                                                else
                                                {
                                                    console.log("NOTHING TODO. Version is actual");
                                                }
                                                
                                    }
       }
       else
       {
           console.log("unexpectadly ls site version null");
           
           
       }
                                
                                
                                
     },
    
    
     
    
    
    
    get_school_name_by_sch_id: function (school_lo,sch_id,datafield) {
        
         for (let k in school_lo) {  //console.log(k); console.log(school_lo[k]['sch_name']);
                   // console.log(school_lo[k]['sch_id']);
                    if (school_lo[k]['sch_id'].toString()==sch_id.toString())
                    {
                        return school_lo[k][datafield];
                    }
               }  
        
        return "~";
    },
    
    
    get_school_list: function (rldata,gid) { 
         localStorage.removeItem('school_lo');
        
        /* ['school_lo']  and ['is_loaded_school_lo'] */
        rldata['is_loaded_school_lo']=false;
        //!!!ПЕРЕЗАВАТЬ СЛОЖНЫЕ ТИПЫ rldata - ОНИ ПЕРЕДАЮТСЯ ПО ССЫЛКЕ и можно внутри подправить rldata['val']!!! ПРОСТЫЕ - значением, если просто передать rldata['val'] то передастся НЕ ПО ССЫЛКЕ!!!!
      
      let is_allow_log=true;
    
      //##!!!TIME IN JSON AND IN JAVASCRIPT IN UTC!!!##
      //let result=false;
      
      
       //Загрузка списка школ
       //ЕСЛИ В ls пусто - загрузим черех axios
      if (localStorage.getItem('school_lo')===null)
      {
         
        if (is_allow_log) { console.log('schoole_list -> byref, load from json ');}
     
      
          axiosRetry(axios, 
                        { 
                            retries: 10, 
                            retryDelay: (retryCount) => {
                                let interval = 1000 * retryCount;
                                if (is_allow_log) {console.log(`Loader request failed. Attempt #${retryCount} will occur after ${interval}ms`);}
                                return interval;
                            }, retryCondition: (error) => {
                                if (is_allow_log) {console.log(error.response.status);}
                                //console.log(error);
                                //при любых ошибках - повторный запрос
                                return true;
                               // return axiosRetry.isNetworkOrIdempotentRequestError(error);// || error.response.status === 404 ;
                            }
                        }
                    );
            
            let url=location.protocol+'//'+window.location.hostname+(location.port ? ':'+location.port: '')+'/games_data/schoole/'+gid+'/schoole.json'+'?timestamp='+(new Date()).getTime();
            const config = {
                headers: { Pragma: 'no-cache'} 
            }  
            axios.get(url,config) 
              .then(response => 
              {  
                  
                   try{ 
                        
                        //ПОЛУЧИЛИ данные из version.json
                        
                        if (is_allow_log){
                            console.log("school_lo");
                            console.log(response['data']['lo']); 
                        }
                        let school_lo= response['data']['lo'];
                        var cjson_school_lo=this.$root.encrypt_json(school_lo);
                        localStorage.setItem('school_lo',cjson_school_lo);                        
                        rldata['school_lo']=school_lo;
                        rldata['is_loaded_school_lo']=true;
                       
                  } catch(e) {
                      
                      //если какая-то ошибка - просто снесем значение в local storage
                      localStorage.removeItem('school_lo');
                      
                      if (is_allow_log) {
                            console.log("error---");
                            console.log(e);
                       }
                      //ничего делать не надо                      
                    }
              
               
              })
            .catch(error => {
                console.log("get schooles, all attemps faild ="+error);
        
                //ROUTE TO PAGE WITH RELOAD BUTTON AND CALLBACK
            })
             .then(function () {
     
            });
      
        }
      else
      {
          //иначе возьмем из ls
          let cjson_school_lo=localStorage.getItem('school_lo');
          rldata['school_lo']=this.$root.decrypt_json(cjson_school_lo);
          rldata['is_loaded_school_lo']=true;
          
          if (is_allow_log) {
            console.log(rldata['school_lo']);
            console.log('schoole_list -> load from ls');
          }
        }
        
        
    },
    
    
    
     get_childt_registrations_list: function (rldata) { 
     
      /* ['childt_registrations'] */
          rldata['is_loaded_childt_registrations']=false;
        //!!!ПЕРЕЗАВАТЬ СЛОЖНЫЕ ТИПЫ rldata - ОНИ ПЕРЕДАЮТСЯ ПО ССЫЛКЕ и можно внутри подправить rldata['val']!!! ПРОСТЫЕ - значением, если просто передать rldata['val'] то передастся НЕ ПО ССЫЛКЕ!!!!
      
      let is_allow_log=true;
     
     
     
     //ВСЕГДА ТРЕМ!!! ПОТОМУ ЧТО ТАМ ПОСТОЯННО ДОБАВЛЯЮТСЯ ДЕТИ!!!
       localStorage.removeItem('childt_registrations');
     
       //ЕСЛИ В ls пусто - загрузим черех axios
      if (localStorage.getItem('childt_registrations')===null)
      {
         
        if (is_allow_log) { console.log('childt_registrations -> byref, load from json ');}
     
      
          axiosRetry(axios, 
                        { 
                            retries: 10, 
                            retryDelay: (retryCount) => {
                                let interval = 1000 * retryCount;
                                if (is_allow_log) {console.log(`Loader request failed. Attempt #${retryCount} will occur after ${interval}ms`);}
                                return interval;
                            }, retryCondition: (error) => {
                                if (is_allow_log) {console.log(error.response.status);}
                                //console.log(error);
                                //при любых ошибках - повторный запрос
                                return true;
                               // return axiosRetry.isNetworkOrIdempotentRequestError(error);// || error.response.status === 404 ;
                            }
                        }
                    );
            
             
            
            console.log(rldata['gid']);
            
              var params = {
                  teachguid:  rldata['teachguid'],
                  gid: rldata['gid']
              };
              
               var url=this.$root.rdata['server_auth']+"/v1/authreg_child/childt_registrations/any";
              
                 axios.post(url, params )                  
                      .then(response => {  
                          
                  if (is_allow_log) {  console.log(response['data']);}
              
                       switch(response['data']['answer_code'])
                       {
                           case "READY":
                            
                            
                                       try{ 
                                             
                                           
                                            let childt_registrations={};
                                            childt_registrations = response['data']['data']['t_chlist'];
                                             

                                            var cjson_childt_registrations=this.$root.encrypt_json(childt_registrations);
                                            localStorage.setItem('childt_registrations',cjson_childt_registrations);
                                            rldata['childt_registrations']=childt_registrations;                       
                                            rldata['is_loaded_childt_registrations']=true;

                                      } catch(e) {

                                          //если какая-то ошибка - просто снесем значение в local storage
                                          localStorage.removeItem('childt_registrations');
                 
                                        }
                             
                               
                                break;
                         
                               
                                default:   
                                            localStorage.removeItem('childt_registrations');
                                       break;
                       }
                      //не выносить отсюда - перестает работать блокировка кнопки
                       //отсчелк кнопки по таймеру this.is_send_sms_disabled=false;
                  })
                  .catch(error => { 
                    if (is_allow_log) { console.log(error);}
                    localStorage.removeItem('childt_registrations'); 
                     
                });
                /* .then(function () {
                     console.log('axios fin');
                   // this.rldata['flagserror_check_code']['is_error_on_code_missmatch']=false;
                  });*/
        
        }
      
      
        
        
    
    
    },
    
    
    
    
    
    
    
    
    
    
    
    get_child_registrations_list: function (rldata) { 
     
      /* ['child_registrations'] */
          rldata['is_loaded_child_registrations']=false;
        //!!!ПЕРЕЗАВАТЬ СЛОЖНЫЕ ТИПЫ rldata - ОНИ ПЕРЕДАЮТСЯ ПО ССЫЛКЕ и можно внутри подправить rldata['val']!!! ПРОСТЫЕ - значением, если просто передать rldata['val'] то передастся НЕ ПО ССЫЛКЕ!!!!
          let is_allow_log=true;
     
      
     //Полверим соответсвие  ранее загруженного списка детей, rldata['parentguid']
     if (localStorage.getItem('child_registrations_'+ rldata['gid'])!==null)
      {
          
          rldata['is_loaded_child_registrations']=true;
          //иначе возьмем из ls
          let cjson_child_registrations=localStorage.getItem('child_registrations_'+ rldata['gid']);
          rldata['child_registrations']=this.$root.decrypt_json(cjson_child_registrations);
          
          if (rldata['child_registrations'].hasOwnProperty('0')){
              
                if (rldata['child_registrations'][0].hasOwnProperty('parentguid'))
                {

                   if(rldata['child_registrations'][0]['parentguid']===rldata['parentguid'])
                   {
                         if (is_allow_log) {
                             console.log('child_registrations -> load from ls');
                             console.log(rldata['child_registrations']);
                        }
                   }
                   else
                   {  
                        if (is_allow_log) {
                             console.log('child_registrations -> MISSMATCH parentguid');
                             console.log('child_registrations -> removeItem child_registrations');
                        }
                         localStorage.removeItem('child_registrations_'+ rldata['gid']);
                         rldata['is_loaded_child_registrations']=false;
                   }
               }
               else
               {
                   if (is_allow_log) {
                             console.log('child_registrations -> error hasOwnProperty(parentguid)');
                             console.log('child_registrations -> removeItem child_registrations');
                        }
                   localStorage.removeItem('child_registrations_'+ rldata['gid']);
                   rldata['is_loaded_child_registrations']=false;
               }
          
       }
       else
               {
                   if (is_allow_log) {
                             console.log('child_registrations -> error hasOwnProperty(0)');
                             console.log('child_registrations -> removeItem child_registrations');
                        }
                   localStorage.removeItem('child_registrations_'+ rldata['gid']);
                   rldata['is_loaded_child_registrations']=false;
               }
       
       
       
       
                  
      }
     
      
     
       //ЕСЛИ В ls пусто - загрузим черех axios
      if (localStorage.getItem('child_registrations_'+ rldata['gid'])===null)
      {
         
        if (is_allow_log) { console.log('child_registrations -> byref, load from json ');}
     
      
          axiosRetry(axios, 
                        { 
                            retries: 10, 
                            retryDelay: (retryCount) => {
                                let interval = 1000 * retryCount;
                                if (is_allow_log) {console.log(`Loader request failed. Attempt #${retryCount} will occur after ${interval}ms`);}
                                return interval;
                            }, retryCondition: (error) => {
                                if (is_allow_log) {console.log(error.response.status);}
                                //console.log(error);
                                //при любых ошибках - повторный запрос
                                return true;
                               // return axiosRetry.isNetworkOrIdempotentRequestError(error);// || error.response.status === 404 ;
                            }
                        }
                    );
            
             
            
            //!РАЗВЕТЛИТЬ ПО GAME_TYPE
            // 
            //     rldata['game_type']
            
              var params = {
                  parentguid:  rldata['parentguid'],
                  gid: rldata['gid']
              };
              
              console.log("params");
              console.log(params);
              
               var url=this.$root.rdata['server_auth']+"/v1/authreg_child/child_registrations/any";
              
                 axios.post(url, params )                  
                      .then(response => {  
                          
                  if (is_allow_log) {  console.log(response['data']);}
              
                       switch(response['data']['answer_code'])
                       {
                           case "READY":
                            
                            
                                       try{ 
                                           console.log(response['data']['data']['t_chlist']['a_registrations']); 
                                            if (is_allow_log){
                                                console.log("child_registrations -> load from axios");
                                                console.log(response['data']['data']['t_chlist']['a_registrations']); 
                                            }
                                            
                                            let child_registrations={};
                                            child_registrations = response['data']['data']['t_chlist'];
                                             
 
                                            var cjson_child_registrations=this.$root.encrypt_json(child_registrations);
                                            localStorage.setItem('child_registrations_'+ rldata['gid'],cjson_child_registrations);
                                            rldata['child_registrations']=child_registrations;                       
                                            rldata['is_loaded_child_registrations']=true;

                                      } catch(e) {

                                        console.log("error exec");
                                          //если какая-то ошибка - просто снесем значение в local storage
                                          localStorage.removeItem('child_registrations_'+ rldata['gid']);
                 
                                        }
                             
                               
                                break;
                         
                               
                                default:   
                                            localStorage.removeItem('child_registrations_'+ rldata['gid']);
                                       break;
                       }
                      //не выносить отсюда - перестает работать блокировка кнопки
                       //отсчелк кнопки по таймеру this.is_send_sms_disabled=false;
                  })
                  .catch(error => { 
                    if (is_allow_log) { console.log(error);}
                    localStorage.removeItem('child_registrations_'+ rldata['gid']); 
                     
                });
                /* .then(function () {
                     console.log('axios fin');
                   //  rldata['flagserror_check_code']['is_error_on_code_missmatch']=false;
                  });*/
        
        }
      
      
        
        
    
    
    },
     
    
  get_task_data: function (rldata,gid) { 
       /* ['task'] */
          rldata['is_loaded_task']=false;        //!!!ПЕРЕЗАВАТЬ СЛОЖНЫЕ ТИПЫ rldata - ОНИ ПЕРЕДАЮТСЯ ПО ССЫЛКЕ и можно внутри подправить rldata['val']!!! ПРОСТЫЕ - значением, если просто передать rldata['val'] то передастся НЕ ПО ССЫЛКЕ!!!!
      
      
           let is_allow_log=true;
    
    
    
    //проверим соответствует ли загруженные в LS карточки GID
  
    
 if (localStorage.getItem('task')!==null)
      {
          
          
          //иначе возьмем из ls
          let cjson_task=localStorage.getItem('task');
          rldata['task']=this.$root.decrypt_json(cjson_task);
          
          if (rldata['task'].hasOwnProperty('gid')){
          
                   if(rldata['task']['gid']===gid)  //DA CHEKAET OTHER GAME TASKS!
                   {
                          if (is_allow_log) {
                                                        console.log('task -> WILL load from ls gid='+gid);
                                                        console.log(rldata['task']);
                                                   }
                                                   
                       /* TODO???? chech_gid_v  или сброс через VERSION.JSON?
                            if (rldata['task'].hasOwnProperty('gid_v')){
          
                                        if(rldata['task']['gid_v']===gid_v)  //DA CHEKAET OTHER GAME TASKS!
                                        {


                                                    if (is_allow_log) {
                                                        console.log('task -> WILL load from ls');
                                                        console.log(rldata['task']);
                                                   }
                                        }             
                                       else
                                       {  
                                            if (is_allow_log) {
                                                 console.log('task -> MISSMATCH gid_v');
                                                 console.log('task -> removeItem task');
                                            }
                                             localStorage.removeItem('task');
                                             rldata['is_loaded_task']=false;
                                       }
                        
                                }             
                                   else
                                   {  
                                        if (is_allow_log) {
                                             console.log('task -> MISSMATCH gid_v');
                                             console.log('task -> removeItem task');
                                        }
                                         localStorage.removeItem('task');
                                         rldata['is_loaded_task']=false;
                                   }
                        */
                        
                   }
                   else
                   {  
                        if (is_allow_log) {
                             console.log('task -> MISSMATCH gid');
                             console.log('task -> removeItem task');
                        }
                         localStorage.removeItem('task');
                         rldata['is_loaded_task']=false;
                   }
            
          
       }
       else
               {
                   if (is_allow_log) {
                             console.log('task -> error hasOwnProperty(gid)');
                             console.log('task -> removeItem task');
                        }
                   localStorage.removeItem('gid');
                   rldata['is_loaded_task']=false;
               }
       
       
       
       
                  
      }
      
      
      
      
      
      
       //Загрузка списка школ
       //ЕСЛИ В ls пусто - загрузим черех axios
      if (localStorage.getItem('task')===null)
      {
         
        if (is_allow_log) { console.log('task -> byref, load from json ');}
     
      
          axiosRetry(axios, 
                        { 
                            retries: 10, 
                            retryDelay: (retryCount) => {
                                let interval = 1000 * retryCount;
                                if (is_allow_log) {console.log(`Loader request failed. Attempt #${retryCount} will occur after ${interval}ms`);}
                                return interval;
                            }, retryCondition: (error) => {
                                if (is_allow_log) {console.log(error.response.status);}
                                //console.log(error);
                                //при любых ошибках - повторный запрос
                                return true;
                               // return axiosRetry.isNetworkOrIdempotentRequestError(error);// || error.response.status === 404 ;
                            }
                        }
                    );
            
            let url=location.protocol+'//'+window.location.hostname+(location.port ? ':'+location.port: '')+'/games_data/tasks/'+gid+'/task.json'+'?timestamp='+(new Date()).getTime();
            const config = {
                headers: { Pragma: 'no-cache'} 
            }  
            axios.get(url,config) 
              .then(response => 
              {  
                  
                   try{ 
                       
                        if (is_allow_log){
                            console.log("task");
                            console.log(response['data']); 
                        }
                        let task= response['data'];
                        
                        var cjson_task=this.$root.encrypt_json(task);
                        localStorage.setItem('task',cjson_task);
                        rldata['task']=task;    
                        rldata['is_loaded_task']=true;
                        rldata['cnt_loaded']=rldata['cnt_loaded']+1;
                       
                  } catch(e) {
                      
                      //если какая-то ошибка - просто снесем значение в local storage
                      localStorage.removeItem('task');
                      
                      if (is_allow_log) {
                            console.log("error---");
                            console.log(e);
                       }
                      //ничего делать не надо                      
                    }
              
               
              })
            .catch(error => {
                console.log("get task, all attemps faild ="+error);
                //ROUTE TO PAGE WITH RELOAD BUTTON AND CALLBACK
            })
             .then(function () {
     
            });
      
        }
      else
      {
          
          //иначе возьмем из ls
          let cjson_task=localStorage.getItem('task');
          rldata['task']=this.$root.decrypt_json(cjson_task);
          rldata['cnt_loaded']=rldata['cnt_loaded']+1;
          rldata['is_loaded_task']=true;
          
          if (is_allow_log) {
            console.log(rldata['task']);
            console.log('task -> load from ls');
          }
        }
        
        
      
      
  },
  
  
  
  
  
  
  
  
  
  
  
  
  
  
      
  get_tickets: function (rldata) { 
       /* ['tickets'] */
         rldata['is_loaded_tickets']=false;        //!!!ПЕРЕЗАВАТЬ СЛОЖНЫЕ ТИПЫ rldata - ОНИ ПЕРЕДАЮТСЯ ПО ССЫЛКЕ и можно внутри подправить rldata['val']!!! ПРОСТЫЕ - значением, если просто передать rldata['val'] то передастся НЕ ПО ССЫЛКЕ!!!!
         rldata['is_allow_full_statistics']=false; 
         let is_allow_log=true;
      
        
          axiosRetry(axios, 
                        { 
                            retries: 3, 
                            retryDelay: (retryCount) => {
                                let interval = 1000 * retryCount;
                                if (is_allow_log) {console.log(`~Loader request failed. Attempt #${retryCount} will occur after ${interval}ms`);}
                                return interval;
                            }, retryCondition: (error) => {
                                if (is_allow_log) {console.log(error.response.status);}
                                //console.log(error);
                                //при любых ошибках - повторный запрос
                                return true;
                               // return axiosRetry.isNetworkOrIdempotentRequestError(error);// || error.response.status === 404 ;
                            }
                        }
                    );
      
      //локально
            let url=location.protocol+'//'+window.location.hostname+(location.port ? ':'+location.port: '')+'/_sales/'+rldata['gid']+'/'+rldata['ctel']+'/tickets.json'+'?timestamp='+(new Date()).getTime();
     
     console.log(url);
     
      const config = {
                headers: { Pragma: 'no-cache'} 
            }  
            axios.get(url,config) 
              .then(response => 
              {  
                  
                   try{ 
                       
                       console.log(response['data']);
                       
                        let tickets= response['data'];
                        rldata['tickets']=tickets;    
                        rldata['is_allow_full_statistics']=tickets['is_allow_full_statistics'];
                        rldata['is_loaded_tickets']=true;
                        //rldata['cnt_loaded']=rldata['cnt_loaded']+1;
                       
                  } catch(e) {
                      
                      if (is_allow_log) {
                            console.log("error---");
                            console.log(e);
                       }
                      //ничего делать не надо                      
                    }
              
               
              })
            .catch(error => {
                console.log("get task, all attemps faild ="+error);
                //ROUTE TO PAGE WITH RELOAD BUTTON AND CALLBACK
            })
             .then(function () {
     
            });
      
       
        
      
      
  },
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
   
  get_pricekoef_data: function (rldata,gid) { 
       /* ['pricekoef'] */
          rldata['is_loaded_pricekoef']=false;        //!!!ПЕРЕЗАВАТЬ СЛОЖНЫЕ ТИПЫ rldata - ОНИ ПЕРЕДАЮТСЯ ПО ССЫЛКЕ и можно внутри подправить rldata['val']!!! ПРОСТЫЕ - значением, если просто передать rldata['val'] то передастся НЕ ПО ССЫЛКЕ!!!!
      
      
           let is_allow_log=true;
    
    
    
    //проверим соответствует ли загруженные в LS карточки GID
  
    
 if (localStorage.getItem('pricekoef')!==null)
      {
          
          
          //иначе возьмем из ls
          let cjson_pricekoef=localStorage.getItem('pricekoef');
          rldata['pricekoef']=this.$root.decrypt_json(cjson_pricekoef);
          
          if (rldata['pricekoef'].hasOwnProperty('gid')){
              
                 

                   if(rldata['pricekoef']['gid']===gid)  //DA CHEKAET OTHER GAME pricekoefS!
                   {
                         if (is_allow_log) {
                             console.log('pricekoef -> WILL load from ls');
                             console.log(rldata['pricekoef']);
                        }
                        
                   }
                   else
                   {  
                        if (is_allow_log) {
                             console.log('pricekoef -> MISSMATCH gid');
                             console.log('pricekoef -> removeItem pricekoef');
                        }
                         localStorage.removeItem('pricekoef');
                         rldata['is_loaded_pricekoef']=false;
                   }
            
          
       }
       else
               {
                   if (is_allow_log) {
                             console.log('pricekoef -> error hasOwnProperty(gid)');
                             console.log('pricekoef -> removeItem pricekoef');
                        }
                   localStorage.removeItem('gid');
                   rldata['is_loaded_pricekoef']=false;
               }
       
       
       
       
                  
      }
      
      
      
      
      
      
       //Загрузка списка школ
       //ЕСЛИ В ls пусто - загрузим черех axios
      if (localStorage.getItem('pricekoef')===null)
      {
         
        if (is_allow_log) { console.log('pricekoef -> byref, load from json ');}
     
      
          axiosRetry(axios, 
                        { 
                            retries: 10, 
                            retryDelay: (retryCount) => {
                                let interval = 1000 * retryCount;
                                if (is_allow_log) {console.log(`Loader request failed. Attempt #${retryCount} will occur after ${interval}ms`);}
                                return interval;
                            }, retryCondition: (error) => {
                                if (is_allow_log) {console.log(error.response.status);}
                                //console.log(error);
                                //при любых ошибках - повторный запрос
                                return true;
                               // return axiosRetry.isNetworkOrIdempotentRequestError(error);// || error.response.status === 404 ;
                            }
                        }
                    );
            
            
            let url=location.protocol+'//'+window.location.hostname+(location.port ? ':'+location.port: '')+'/games_data/shop/'+gid+'/pricekoef.json'+'?timestamp='+(new Date()).getTime();
            const config = {
                headers: { Pragma: 'no-cache'} 
            }  
            axios.get(url,config) 
              .then(response => 
              {  
                  
                   try{ 
                       
                        if (is_allow_log){
                            console.log("pricekoef");
                            console.log(response['data']); 
                        }
                        let pricekoef= response['data'];
                        
                        var cjson_pricekoef=this.$root.encrypt_json(pricekoef);
                        localStorage.setItem('pricekoef',cjson_pricekoef);
                        rldata['pricekoef']=pricekoef;    
                        rldata['is_loaded_pricekoef']=true;
                        rldata['cnt_loaded']=rldata['cnt_loaded']+1;
                       
                  } catch(e) {
                      
                      //если какая-то ошибка - просто снесем значение в local storage
                      localStorage.removeItem('pricekoef');
                      
                      if (is_allow_log) {
                            console.log("error---");
                            console.log(e);
                       }
                      //ничего делать не надо                      
                    }
              
               
              })
            .catch(error => {
                console.log("get pricekoef, all attemps faild ="+error);
                //ROUTE TO PAGE WITH RELOAD BUTTON AND CALLBACK
            })
             .then(function () {
     
            });
      
        }
      else
      {
          
          //иначе возьмем из ls
          let cjson_pricekoef=localStorage.getItem('pricekoef');
          rldata['pricekoef']=this.$root.decrypt_json(cjson_pricekoef);
          rldata['cnt_loaded']=rldata['cnt_loaded']+1;
          rldata['is_loaded_pricekoef']=true;
          
          if (is_allow_log) {
            console.log(rldata['pricekoef']);
            console.log('pricekoef -> load from ls');
          }
        }
        
        
      
      
  },
  
  
  
  
  
  
  
  
  
    
  get_shop_data: function (rldata,gid) { 
       /* ['shop'] */
          rldata['is_loaded_shop']=false;        //!!!ПЕРЕЗАВАТЬ СЛОЖНЫЕ ТИПЫ rldata - ОНИ ПЕРЕДАЮТСЯ ПО ССЫЛКЕ и можно внутри подправить rldata['val']!!! ПРОСТЫЕ - значением, если просто передать rldata['val'] то передастся НЕ ПО ССЫЛКЕ!!!!
      
      
           let is_allow_log=true;
    
    
    
    //проверим соответствует ли загруженные в LS карточки GID
  
    
 if (localStorage.getItem('shop')!==null)
      {
          
          
          //иначе возьмем из ls
          let cjson_shop=localStorage.getItem('shop');
          rldata['shop']=this.$root.decrypt_json(cjson_shop);
          
          if (rldata['shop'].hasOwnProperty('gid')){
              
                 

                   if(rldata['shop']['gid']===gid)  //DA CHEKAET OTHER GAME shopS!
                   {
                         if (is_allow_log) {
                             console.log('shop -> WILL load from ls');
                             console.log(rldata['shop']);
                        }
                        
                   }
                   else
                   {  
                        if (is_allow_log) {
                             console.log('shop -> MISSMATCH gid');
                             console.log('shop -> removeItem shop');
                        }
                         localStorage.removeItem('shop');
                         rldata['is_loaded_shop']=false;
                   }
            
          
       }
       else
               {
                   if (is_allow_log) {
                             console.log('shop -> error hasOwnProperty(gid)');
                             console.log('shop -> removeItem shop');
                        }
                   localStorage.removeItem('gid');
                   rldata['is_loaded_shop']=false;
               }
       
       
       
       
                  
      }
      
      
      
      
      
      
       //Загрузка списка школ
       //ЕСЛИ В ls пусто - загрузим черех axios
      if (localStorage.getItem('shop')===null)
      {
         
        if (is_allow_log) { console.log('shop -> byref, load from json ');}
     
      
          axiosRetry(axios, 
                        { 
                            retries: 10, 
                            retryDelay: (retryCount) => {
                                let interval = 1000 * retryCount;
                                if (is_allow_log) {console.log(`Loader request failed. Attempt #${retryCount} will occur after ${interval}ms`);}
                                return interval;
                            }, retryCondition: (error) => {
                                if (is_allow_log) {console.log(error.response.status);}
                                //console.log(error);
                                //при любых ошибках - повторный запрос
                                return true;
                               // return axiosRetry.isNetworkOrIdempotentRequestError(error);// || error.response.status === 404 ;
                            }
                        }
                    );
            
            let url=location.protocol+'//'+window.location.hostname+(location.port ? ':'+location.port: '')+'/games_data/shop/'+gid+'/products.json'+'?timestamp='+(new Date()).getTime();
            const config = {
                headers: { Pragma: 'no-cache'} 
            }  
            axios.get(url,config) 
              .then(response => 
              {  
                  
                   try{ 
                       
                        if (is_allow_log){
                            console.log("shop");
                            console.log(response['data']); 
                        }
                        let shop= response['data'];
                        
                        var cjson_shop=this.$root.encrypt_json(shop);
                        localStorage.setItem('shop',cjson_shop);
                        rldata['shop']=shop;    
                        rldata['is_loaded_shop']=true;
                        rldata['cnt_loaded']=rldata['cnt_loaded']+1;
                       
                  } catch(e) {
                      
                      //если какая-то ошибка - просто снесем значение в local storage
                      localStorage.removeItem('shop');
                      
                      if (is_allow_log) {
                            console.log("error---");
                            console.log(e);
                       }
                      //ничего делать не надо                      
                    }
              
               
              })
            .catch(error => {
                console.log("get shop, all attemps faild ="+error);
                //ROUTE TO PAGE WITH RELOAD BUTTON AND CALLBACK
            })
             .then(function () {
     
            });
      
        }
      else
      {
          
          //иначе возьмем из ls
          let cjson_shop=localStorage.getItem('shop');
          rldata['shop']=this.$root.decrypt_json(cjson_shop);
          rldata['cnt_loaded']=rldata['cnt_loaded']+1;
          rldata['is_loaded_shop']=true;
          
          if (is_allow_log) {
            console.log(rldata['shop']);
            console.log('shop -> load from ls');
          }
        }
        
        
      
      
  },
  
  
  
  
  
  
  
  
  
  
  
  
    get_achives_data: function (rldata,rid) { 
          /* ['achives'] */
          rldata['is_loaded_achives']=false;        //!!!ПЕРЕЗАВАТЬ СЛОЖНЫЕ ТИПЫ rldata - ОНИ ПЕРЕДАЮТСЯ ПО ССЫЛКЕ и можно внутри подправить rldata['val']!!! ПРОСТЫЕ - значением, если просто передать rldata['val'] то передастся НЕ ПО ССЫЛКЕ!!!!
      
      let is_allow_log=true;
    
        //
        
        
 if (localStorage.getItem('achives')!==null)
      {
          
           
          
          let cjson_achives=localStorage.getItem('achives');
          rldata['achives']=this.$root.decrypt_json(cjson_achives);
          
          if (rldata['achives'].hasOwnProperty('rid')){
              
                 

                   if(rldata['achives']['rid']===rid)  //DA CHEKAET OTHER GAME achives!
                   {
                         if (is_allow_log) {
                             console.log('achives -> WILL load from ls');
                             console.log(rldata['achives']);
                        }
                   }
                   else
                   {  
                        if (is_allow_log) {
                             console.log('achives -> MISSMATCH rid');
                             console.log('achives -> removeItem achives');
                        }
                         localStorage.removeItem('achives');
                         rldata['is_loaded_achives']=false;
                   }
            
          
       }
       else
               {
                   if (is_allow_log) {
                             console.log('achives -> error hasOwnProperty(rid)');
                             console.log('achives -> removeItem achives');
                        }
                   localStorage.removeItem('achives');
                   rldata['is_loaded_achives']=false;
               }
       
       
       
       
                  
      }
      
        
        
        
        
        
        
        

      
      
       //Загрузка списка школ
       //ЕСЛИ В ls пусто - загрузим черех axios
      if (localStorage.getItem('achives')===null)
      {
         
        if (is_allow_log) { console.log('achives -> byref, load from json ');}
     
      
          axiosRetry(axios, 
                        { 
                            retries: 10, 
                            retryDelay: (retryCount) => {
                                let interval = 1000 * retryCount;
                                if (is_allow_log) {console.log(`Loader request failed. Attempt #${retryCount} will occur after ${interval}ms`);}
                                return interval;
                            }, retryCondition: (error) => {
                                if (is_allow_log) {console.log(error.response.status);}
                                //console.log(error);
                                //при любых ошибках - повторный запрос
                                return true;
                               // return axiosRetry.isNetworkOrIdempotentRequestError(error);// || error.response.status === 404 ;
                            }
                        }
                    );
            
            let url=location.protocol+'//'+window.location.hostname+(location.port ? ':'+location.port: '')+'/achives.json'+'?timestamp='+(new Date()).getTime();
            const config = {
                headers: { Pragma: 'no-cache'} 
            }  
            axios.get(url,config) 
              .then(response => 
              {  
                  
                   try{ 
                       
                        if (is_allow_log){
                            console.log("achives");
                            console.log(response['data']); 
                        }
                        let achives= response['data'];
                        achives['rid']=rid; 
                        
                        var cjson_achives=this.$root.encrypt_json(achives);
                        localStorage.setItem('achives',cjson_achives);
                        rldata['achives']=achives;                         
                        rldata['is_loaded_achives']=true;
                        rldata['cnt_loaded']=rldata['cnt_loaded']+1;
                        
                  } catch(e) {
                      
                      //если какая-то ошибка - просто снесем значение в local storage
                      localStorage.removeItem('achives');
                      
                      if (is_allow_log) {
                            console.log("error---");
                            console.log(e);
                       }
                      //ничего делать не надо                      
                    }
              
               
              })
            .catch(error => {
                console.log("get achives, all attemps faild ="+error);
                //ROUTE TO PAGE WITH RELOAD BUTTON AND CALLBACK
            })
             .then(function () {
     
            });
      
        }
      else
      {
          rldata['is_loaded_achives']=true;
          //иначе возьмем из ls
          let cjson_achives=localStorage.getItem('achives');
          rldata['achives']=this.$root.decrypt_json(cjson_achives);
          
          if (is_allow_log) {
            console.log(rldata['achives']);
            console.log('achives -> load from ls');
          }
        }
        
        
    },
    
  
  
   
  get_prodback_data: function (rldata,rid,gid) { 
       /* ['prodback'] */
          rldata['is_loaded_prodback']=false;        //!!!ПЕРЕЗАВАТЬ СЛОЖНЫЕ ТИПЫ rldata - ОНИ ПЕРЕДАЮТСЯ ПО ССЫЛКЕ и можно внутри подправить rldata['val']!!! ПРОСТЫЕ - значением, если просто передать rldata['val'] то передастся НЕ ПО ССЫЛКЕ!!!!
     
      
           let is_allow_log=true;
    
    
    
    //проверим соответствует ли загруженные в LS карточки GID
  
    
 if (localStorage.getItem('prodback')!==null)
      {
          
           
          
          let cjson_prodback=localStorage.getItem('prodback');
          rldata['prodback']=this.$root.decrypt_json(cjson_prodback);
          
          if (rldata['prodback'].hasOwnProperty('rid') && rldata['prodback'].hasOwnProperty('gid')){
              
                 

                   if(rldata['prodback']['rid']===rid && rldata['prodback']['gid']===gid)  //DA CHEKAET OTHER GAME prodback!
                   {
                         if (is_allow_log) {
                             console.log('prodback -> WILL load from ls');
                             console.log(rldata['prodback']);
                        }
                   }
                   else
                   {  
                        if (is_allow_log) {
                             console.log('prodback -> MISSMATCH rig or gid');
                             console.log('prodback -> removeItem prodback');
                        }
                         localStorage.removeItem('prodback');
                         rldata['is_loaded_prodback']=false;
                   }
            
          
       }
       else
               {
                   if (is_allow_log) {
                             console.log('prodback -> error hasOwnProperty(rid or gid)');
                             console.log('prodback -> removeItem prodback');
                        }
                   localStorage.removeItem('prodback');
                   rldata['is_loaded_prodback']=false;
               }
       
       
       
       
                  
      }
      
      
      
      
      
      
       //Загрузка списка школ
       //ЕСЛИ В ls пусто - загрузим черех axios
      if (localStorage.getItem('prodback')===null)
      {
         
        if (is_allow_log) { console.log('prodback -> byref, load from json ');}
     
      
          axiosRetry(axios, 
                        { 
                            retries: 10, 
                            retryDelay: (retryCount) => {
                                let interval = 1000 * retryCount;
                                if (is_allow_log) {console.log(`Loader request failed. Attempt #${retryCount} will occur after ${interval}ms`);}
                                return interval;
                            }, retryCondition: (error) => {
                                if (is_allow_log) {console.log(error.response.status);}
                                //console.log(error);
                                //при любых ошибках - повторный запрос
                                return true;
                               // return axiosRetry.isNetworkOrIdempotentRequestError(error);// || error.response.status === 404 ;
                            }
                        }
                    );
            
         
          var params = {
                  rid:  rid,
                  gid:  gid,
              };
               
             var url=this.$root.rdata['server_action']+"/v1/action_service/get_prodback2/any";
             
            axios.post(url, params )    
              .then(response => 
              {  
                  
                  //ОБНОВИМ prodback
                   try{ 
                        if (is_allow_log){
                            console.log("prodback");console.log(response['data']); 
                        }
                        let prodback= response['data']['data'];
                        if (prodback['a_prodback'].hasOwnProperty(0))
                        {console.log("FILLED");}
                        else
                        {console.log("EMPTY");}
                        var cjson_prodback=this.$root.encrypt_json(prodback);
                        localStorage.setItem('prodback',cjson_prodback);
                        rldata['prodback']=prodback;                             
                        rldata['is_loaded_prodback']=true;
                        rldata['cnt_loaded']=rldata['cnt_loaded']+1;
                    } catch(e) {//если какая-то ошибка - просто снесем значение в local storage
                      localStorage.removeItem('prodback');
                      if (is_allow_log) {
                            console.log("error---");console.log(e);
                       }
                      //ничего делать не надо                      
                    }
              
               
              })
            .catch(error => {
                console.log("get prodback, all attemps faild ="+error);
                //ROUTE TO PAGE WITH RELOAD BUTTON AND CALLBACK
            })
             .then(function () {
     
            });
      
        }
      else
      {
          
          //иначе возьмем из ls
          let cjson_prodback=localStorage.getItem('prodback');
          rldata['prodback']=this.$root.decrypt_json(cjson_prodback);
          rldata['cnt_loaded']=rldata['cnt_loaded']+1;
          rldata['is_loaded_prodback']=true;
          
          if (is_allow_log) {
            console.log(rldata['prodback']);
            console.log('prodback -> load from ls');
          }
        }
        
        
      
      
  },
  
  
  
  
  
    
  get_sendback_data: function (rldata,rid,gid) { 
       /* ['sendback'] */
          rldata['is_loaded_sendback']=false;        //!!!ПЕРЕЗАВАТЬ СЛОЖНЫЕ ТИПЫ rldata - ОНИ ПЕРЕДАЮТСЯ ПО ССЫЛКЕ и можно внутри подправить rldata['val']!!! ПРОСТЫЕ - значением, если просто передать rldata['val'] то передастся НЕ ПО ССЫЛКЕ!!!!
      
      
           let is_allow_log=true;
    
    
    
    //проверим соответствует ли загруженные в LS карточки GID
  
    
 if (localStorage.getItem('sendback')!==null)
      {
          
           
          
          let cjson_sendback=localStorage.getItem('sendback');
          rldata['sendback']=this.$root.decrypt_json(cjson_sendback);
          
          if (rldata['sendback'].hasOwnProperty('rid') && rldata['sendback'].hasOwnProperty('gid')){
              
                 

                   if(rldata['sendback']['rid']===rid && rldata['sendback']['gid']===gid)  //DA CHEKAET OTHER GAME sendback!
                   {
                         if (is_allow_log) {
                             console.log('sendback -> WILL load from ls');
                             console.log(rldata['sendback']);
                        }
                   }
                   else
                   {  
                        if (is_allow_log) {
                             console.log('sendback -> MISSMATCH gid or rid');
                             console.log('sendback -> removeItem sendback');
                        }
                         localStorage.removeItem('sendback');
                         rldata['is_loaded_sendback']=false;
                   }
            
          
       }
       else
               {
                   if (is_allow_log) {
                             console.log('sendback -> error hasOwnProperty(rid or gid)');
                             console.log('sendback -> removeItem sendback');
                        }
                   localStorage.removeItem('sendback');
                   rldata['is_loaded_sendback']=false;
               }
       
       
       
       
                  
      }
      
      
      
      
      
      
       //Загрузка 
       //ЕСЛИ В ls пусто - загрузим черех axios
      if (localStorage.getItem('sendback')===null)
      {
         
        if (is_allow_log) { console.log('sendback -> byref, load from json ');}
     
      
          axiosRetry(axios, 
                        { 
                            retries: 10, 
                            retryDelay: (retryCount) => {
                                let interval = 1000 * retryCount;
                                if (is_allow_log) {console.log(`Loader request failed. Attempt #${retryCount} will occur after ${interval}ms`);}
                                return interval;
                            }, retryCondition: (error) => {
                                if (is_allow_log) {console.log(error.response.status);}
                                //console.log(error);
                                //при любых ошибках - повторный запрос
                                return true;
                               // return axiosRetry.isNetworkOrIdempotentRequestError(error);// || error.response.status === 404 ;
                            }
                        }
                    );
            
         
          var params = {
                  rid:  rid,
                  gid:  gid,
              };
               
             var url=this.$root.rdata['server_action']+"/v1/action_service/get_sendback2/any";
             
            axios.post(url, params )    
              .then(response => 
              {  
                  console.log(response['data']);
                  //ОБНОВИМ SENDBACK
                   try{ 
                         console.log("sendback");console.log(response['data']); 
                        
                        let sendback= response['data']['data'];
                        if (sendback['a_sendback'].hasOwnProperty(0))
                        {console.log("FILL");}
                        else
                        {console.log("EMPTY");}
                        var cjson_sendback=this.$root.encrypt_json(sendback);
                        localStorage.setItem('sendback',cjson_sendback);
                        //console.log("cjson_sendback="+cjson_sendback);
                        rldata['sendback']=sendback;                             
                        rldata['is_loaded_sendback']=true;
                        rldata['cnt_loaded']=rldata['cnt_loaded']+1;
                    } catch(e) {//если какая-то ошибка - просто снесем значение в local storage
                      localStorage.removeItem('sendback');
                      if (is_allow_log) {
                            console.log("error---");console.log(e);
                       }
                      //ничего делать не надо                      
                    }
              
               
              })
            .catch(error => {
                console.log("get sendback, all attemps faild ="+error);
                //ROUTE TO PAGE WITH RELOAD BUTTON AND CALLBACK
            })
             .then(function () {
     
            });
      
        }
      else
      {
          
          //иначе возьмем из ls
          let cjson_sendback=localStorage.getItem('sendback');
          rldata['sendback']=this.$root.decrypt_json(cjson_sendback);
          rldata['cnt_loaded']=rldata['cnt_loaded']+1;
          rldata['is_loaded_sendback']=true;
          
          if (is_allow_log) {
            console.log(rldata['sendback']);
            console.log('sendback -> load from ls');
          }
        }
        
        
      
      
  },
  
  
   
   
  
  
  get_onlinetimings_list: function (rldata) { 
          /* ['onlinetimings'] */
          rldata['is_loaded_onlinetimings']=false;        //!!!ПЕРЕЗАВАТЬ СЛОЖНЫЕ ТИПЫ rldata - ОНИ ПЕРЕДАЮТСЯ ПО ССЫЛКЕ и можно внутри подправить rldata['val']!!! ПРОСТЫЕ - значением, если просто передать rldata['val'] то передастся НЕ ПО ССЫЛКЕ!!!!
      
      let is_allow_log=true;
    
      //##!!!TIME IN JSON AND IN JAVASCRIPT IN UTC!!!##
      //let result=false;
      
      
       //Загрузка списка школ
       //ЕСЛИ В ls пусто - загрузим черех axios
      if (localStorage.getItem('onlinetimings')===null)
      {
         
        if (is_allow_log) { console.log('onlinetimings -> byref, load from json ');}
     
      
          axiosRetry(axios, 
                        { 
                            retries: 10, 
                            retryDelay: (retryCount) => {
                                let interval = 1000 * retryCount;
                                if (is_allow_log) {console.log(`Loader request failed. Attempt #${retryCount} will occur after ${interval}ms`);}
                                return interval;
                            }, retryCondition: (error) => {
                                if (is_allow_log) {console.log(error.response.status);}
                                //console.log(error);
                                //при любых ошибках - повторный запрос
                                return true;
                               // return axiosRetry.isNetworkOrIdempotentRequestError(error);// || error.response.status === 404 ;
                            }
                        }
                    );
            
            let url=location.protocol+'//'+window.location.hostname+(location.port ? ':'+location.port: '')+'/onlinetimings.json'+'?timestamp='+(new Date()).getTime();
            const config = {
                headers: { Pragma: 'no-cache'} 
            }  
            axios.get(url,config) 
              .then(response => 
              {  
                  
                   try{ 
                       
                        if (is_allow_log){
                            console.log("onlinetimings");
                            console.log(response['data']); 
                        }
                        let onlinetimings= response['data'];
                        
                        var cjson_onlinetimings=this.$root.encrypt_json(onlinetimings);
                        localStorage.setItem('onlinetimings',cjson_onlinetimings);
                        rldata['onlinetimings']=onlinetimings;                       
                        rldata['is_loaded_onlinetimings']=true;
                       
                  } catch(e) {
                      
                      //если какая-то ошибка - просто снесем значение в local storage
                      localStorage.removeItem('onlinetimings');
                      
                      if (is_allow_log) {
                            console.log("error---");
                            console.log(e);
                       }
                      //ничего делать не надо                      
                    }
              
               
              })
            .catch(error => {
                console.log("get onlinetimings, all attemps faild ="+error);
                //ROUTE TO PAGE WITH RELOAD BUTTON AND CALLBACK
            })
             .then(function () {
     
            });
      
        }
      else
      {
          rldata['is_loaded_onlinetimings']=true;
          //иначе возьмем из ls
          let cjson_onlinetimings=localStorage.getItem('onlinetimings');
          rldata['onlinetimings']=this.$root.decrypt_json(cjson_onlinetimings);
          
          if (is_allow_log) {
            console.log(rldata['onlinetimings']);
            console.log('onlinetimings -> load from ls');
          }
        }
        
        
    },
    
  
   
  
   get_statdata_list: function (rldata,is_loaded_flag, force_reload) { 
   //a_user
       rldata['is_loaded_statdata']=is_loaded_flag;
       
       //ЕСТЬ РАЗРЕСЫ GID и CLASS через путь-папку в YC
       
        let is_allow_log=true;
    
      //##!!!TIME IN JSON AND IN JAVASCRIPT IN UTC!!!##
      //let result=false;
      
        /*ПРОВЕРИТЬ!!! что в LS лежит нужная дата по gid */
          /*иначе сброс!*/
          
           console.log("WWWWWWWWWW|"+rldata['gid']+"|"+rldata['classnum']);
      
       if (localStorage.getItem('statdata')!==null){
           
                let cjson_statdata=localStorage.getItem('statdata');
                let statdata =this.$root.decrypt_json(cjson_statdata);
               
           console.log(statdata);
           console.log(statdata['classnum']+"|"+rldata['classnum']);
           console.log(statdata['gid']+"|"+rldata['gid']);
             
             if (parseInt(statdata['classnum'])!==parseInt(rldata['classnum']) || statdata['gid']!==rldata['gid'])
             {
                 localStorage.removeItem("statdata");
                 console.log("STATDATA REMOVED FROM LS - missmatch gid or classnum");
             }
                     
                
        }
     
      
       //Загрузка списка школ
       //ЕСЛИ В ls пусто - загрузим черех axios
      if (localStorage.getItem('statdata')===null)
      {
           
         
        if (is_allow_log) { console.log('statdata -> byref, load from json ');}
     
      
          axiosRetry(axios, 
                        { 
                            retries: 10, 
                            retryDelay: (retryCount) => {
                                let interval = 1000 * retryCount;
                                if (is_allow_log) {console.log(`Loader request failed. Attempt #${retryCount} will occur after ${interval}ms`);}
                                return interval;
                            }, retryCondition: (error) => {
                                if (is_allow_log) {console.log(error.response.status);}
                                //console.log(error);
                                //при любых ошибках - повторный запрос
                                return true;
                               // return axiosRetry.isNetworkOrIdempotentRequestError(error);// || error.response.status === 404 ;
                            }
                        }
                    );
            
            let url=location.protocol+'//'+window.location.hostname+(location.port ? ':'+location.port: '')+'/_stat/'+rldata['gid']+'/statdata_'+rldata['classnum']+'.json'+'?timestamp='+(new Date()).getTime();
            const config = {
                headers: { Pragma: 'no-cache'} 
            }  
            axios.get(url,config) 
              .then(response => 
              {  
                  
                   try{ 
                       
                        if (is_allow_log){
                            console.log("statdata");
                            console.log(response['data']); 
                        }
                        let statdata= response['data'];
                        
                        console.log(url);
                         console.log(statdata);
                        
                        var cjson_statdata=this.$root.encrypt_json(statdata);
                        localStorage.setItem('statdata',cjson_statdata);
                        rldata['statdata']=statdata;                       
                        rldata['is_loaded_statdata']=true;
                        if (force_reload)
                        {this.$router.go(this.$router.currentRoute); console.log("ROUTER RELOAD PAGE"); /*document.location.reload();*/}
                       
                  } catch(e) {
                      
                      //если какая-то ошибка - просто снесем значение в local storage
                      localStorage.removeItem('statdata');
                      
                      if (is_allow_log) {
                            console.log("error---");
                            console.log(e);
                       }
                      //ничего делать не надо                      
                    }
              
               
              })
            .catch(error => {
                console.log("get statdata, all attemps faild ="+error);
                //ROUTE TO PAGE WITH RELOAD BUTTON AND CALLBACK
            })
             .then(function () {
     
            });
      
        }
      else
      {
          rldata['is_loaded_statdata']=true;
          //иначе возьмем из ls
          let cjson_statdata=localStorage.getItem('statdata');
          rldata['statdata']=this.$root.decrypt_json(cjson_statdata);
          
          if (is_allow_log) {
            console.log(rldata['statdata']);
            console.log('statdata -> load from ls');
          }
        }
        
        
       
   
   },
  
    get_onlinegames_list: function (rldata) { 
        
       
       
        
          /* ['onlinegames'] */
          rldata['is_loaded_onlinegames']=false;        //!!!ПЕРЕЗАВАТЬ СЛОЖНЫЕ ТИПЫ rldata - ОНИ ПЕРЕДАЮТСЯ ПО ССЫЛКЕ и можно внутри подправить rldata['val']!!! ПРОСТЫЕ - значением, если просто передать rldata['val'] то передастся НЕ ПО ССЫЛКЕ!!!!
      
      let is_allow_log=true;
    
      //##!!!TIME IN JSON AND IN JAVASCRIPT IN UTC!!!##
      //let result=false;
      
      
       //Загрузка списка школ
       //ЕСЛИ В ls пусто - загрузим черех axios
      if (localStorage.getItem('onlinegames')===null)
      {
         
        if (is_allow_log) { console.log('onlinegames -> byref, load from json ');}
     
      
          axiosRetry(axios, 
                        { 
                            retries: 10, 
                            retryDelay: (retryCount) => {
                                let interval = 1000 * retryCount;
                                if (is_allow_log) {console.log(`Loader request failed. Attempt #${retryCount} will occur after ${interval}ms`);}
                                return interval;
                            }, retryCondition: (error) => {
                                if (is_allow_log) {console.log(error.response.status);}
                                //console.log(error);
                                //при любых ошибках - повторный запрос
                                return true;
                               // return axiosRetry.isNetworkOrIdempotentRequestError(error);// || error.response.status === 404 ;
                            }
                        }
                    );
            
            let url=location.protocol+'//'+window.location.hostname+(location.port ? ':'+location.port: '')+'/onlinegames.json'+'?timestamp='+(new Date()).getTime();
            const config = {
                headers: { Pragma: 'no-cache'} 
            }  
            axios.get(url,config) 
              .then(response => 
              {  
                  
                   try{ 
                       
                        if (is_allow_log){
                            console.log("onlinegames");
                            console.log(response['data']); 
                        }
                        let onlinegames= response['data'];
                        
                        
                        //показывать EOO2 только если в localstorage есть флаг
                        //!! флаг в localstorage выставляется в ctel.vue 
                        /*
                        if (localStorage.getItem('is_allow_EOO2')===null)
                        {   
                            for (let k in onlinegames['games']) {   
                                       if (onlinegames['games'][k]['gid'].toString()==="EOO2")
                                       {
                                           onlinegames['games'][k]['is_enabled']=false; 
                                       }
                            }
                        }
                        */
                        
                        var cjson_onlinegames=this.$root.encrypt_json(onlinegames);
                        localStorage.setItem('onlinegames',cjson_onlinegames);
                        rldata['onlinegames']=onlinegames;                       
                        rldata['is_loaded_onlinegames']=true;
                       
                  } catch(e) {
                      
                      //если какая-то ошибка - просто снесем значение в local storage
                      localStorage.removeItem('onlinegames');
                      
                      if (is_allow_log) {
                            console.log("error---");
                            console.log(e);
                       }
                      //ничего делать не надо                      
                    }
              
               
              })
            .catch(error => {
                console.log("get onlinegames, all attemps faild ="+error);
                //ROUTE TO PAGE WITH RELOAD BUTTON AND CALLBACK
            })
             .then(function () {
     
            });
      
        }
      else
      {
          rldata['is_loaded_onlinegames']=true;
          //иначе возьмем из ls
          let cjson_onlinegames=localStorage.getItem('onlinegames');
          rldata['onlinegames']=this.$root.decrypt_json(cjson_onlinegames);
          
          if (is_allow_log) {
            console.log(rldata['onlinegames']);
            console.log('onlinegames -> load from ls');
          }
        }
        
        
    },
    
     
    /*
    capitalizeFirstLetter: function (string) 
      {  
            return string.charAt(0).toUpperCase() + string.slice(1);
          },*/
    
 auth: function (response) {
     
      //================   ВЫНЕСТИ В ФУНКЦИЮ АВТОРИЗАЦИИ!!!
      console.log("INITIAL DATA:");
      console.log(response['data']['data']['initial_data']);
                                var a_users=response['data']['data']['initial_data']['a_users'][0];
                                var cjson_users=this.$root.encrypt_json(a_users); 
                                
                                var a_childs=response['data']['data']['initial_data']['a_childs'];
                                var cjson_childs=this.$root.encrypt_json(a_childs); 
                                
                                var a_games=response['data']['data']['initial_data']['a_games'];
                                var cjson_games=this.$root.encrypt_json(a_games); 
                                
                                var a_subjects=response['data']['data']['initial_data']['a_subjects'];
                                var cjson_subjects=this.$root.encrypt_json(a_subjects); 
                                
                        //обновим локальное хранилище
                                
                                localStorage.setItem('active_users[cjson_users]',      cjson_users );
                                localStorage.setItem('active_childs[cjson_childs]',   cjson_childs );
                                localStorage.setItem('active_games[cjson_games]',   cjson_games );
                                localStorage.setItem('active_subjects[cjson_subjects]', cjson_subjects );
                              
                              
                            //===================   
 
},
    
  
       
 auth_child: function (response) {
     //================   ВЫНЕСТИ В ФУНКЦИЮ АВТОРИЗАЦИИ!!!
                                var a_registrations=response['data']['data']['a_registrations'];
                                var cjson_registrations=this.$root.encrypt_json(a_registrations); 
                                //отправим закриптованную регистрационную дату наверх
                                //this.$root.active_user['cjson_registrations']=cjson_registrations; 
                              
                        //обновим локальное хранилище
                          
                                localStorage.setItem('active_child[cjson_registrations]', cjson_registrations );
                              
                            //===================   
 
},
     
     
     
     
    is_new_achives: function (rldata) {  
         //{  ['achives_out']['lvlopen']['curr_achive_lvl']: "5", ['achives_out']['cardssolved']['curr_achive_lvl']: "1", ['achives_out']['olimpicswoked']['curr_achive_lvl']: "3"} 
    let out={};
    out['lvlopen']="-1";
    out['cardssolved']="-1";
    out['olimpicswoked']="-1";
     
     
     let cjson_curr_achives=  localStorage.getItem('curr_achive_lvls['+rldata['a_registrations']['rid']+']');
     console.log("cjson_curr_achives");
 
     
     
     if (cjson_curr_achives!==null)  //Если уже есть хранилище ачивок...
     {
          var achives_from_ls=this.$root.decrypt_json(cjson_curr_achives);
          
          //console.log("TEST lvlopen trigger");
          //console.log(parseInt(achives_from_ls['lvlopen_saved_al']));
          //console.log(parseInt(rldata['achives_out']['lvlopen']['curr_achive_lvl']));
          
          if ( parseInt(achives_from_ls['lvlopen_saved_al'])< parseInt(rldata['achives_out']['lvlopen']['curr_achive_lvl']))
          {
              console.log('NEW lvlopen|curr_achive_lvl='+rldata['achives_out']['lvlopen']['curr_achive_lvl']);
              out['lvlopen']=rldata['achives_out']['lvlopen']['curr_achive_lvl'];
          }
          
          if ( parseInt(achives_from_ls['cardssolved_saved_al'])< parseInt(rldata['achives_out']['cardssolved']['curr_achive_lvl']))
          {
              console.log('NEW cardssolved|curr_achive_lvl='+rldata['achives_out']['cardssolved']['curr_achive_lvl']);
              out['cardssolved']=rldata['achives_out']['cardssolved']['curr_achive_lvl'];
          }
          
          if ( parseInt(achives_from_ls['olimpicswoked_saved_al'])< parseInt(rldata['achives_out']['olimpicswoked']['curr_achive_lvl']))
          {
              console.log('NEW olimpicswoked|curr_achive_lvl='+rldata['achives_out']['olimpicswoked']['curr_achive_lvl']);
              out['olimpicswoked']=rldata['achives_out']['olimpicswoked']['curr_achive_lvl'];
          }
          
         
     }else
     {
         ////Если еще нет хранилища ачивок...
         out['lvlopen']=rldata['achives_out']['lvlopen']['curr_achive_lvl'];
         out['cardssolved']=rldata['achives_out']['cardssolved']['curr_achive_lvl'];
         out['olimpicswoked']=rldata['achives_out']['olimpicswoked']['curr_achive_lvl'];
     }
     
     
     
    
        let a_saved_achives={};
        a_saved_achives['lvlopen_saved_al']= rldata['achives_out']['lvlopen']['curr_achive_lvl'];
        a_saved_achives['cardssolved_saved_al']=rldata['achives_out']['cardssolved']['curr_achive_lvl'];
        a_saved_achives['olimpicswoked_saved_al']=rldata['achives_out']['olimpicswoked']['curr_achive_lvl'];
      
      console.log("a_saved_achives");
        console.log(a_saved_achives);
        
     var cjson_saved_achives=this.$root.encrypt_json(a_saved_achives);   
     localStorage.setItem('curr_achive_lvls['+rldata['a_registrations']['rid']+']', cjson_saved_achives );
        
        
        return out;
   },  
     
     
     
    calc_actives: function (rldata) { 
    
    let is_allow_log=false;
    
     if (is_allow_log) {
    console.log("rldata['achives']");
    console.log(rldata['achives']);
     }
//rldata['achives']       100% filled  
    //lvlopen
            //levels
                    //1
                            //cnt,  text, header, img
                            
                            
                            
//sendback   may be empy

 //console.log("rldata['sendback']");
 //console.log(rldata['sendback']['a_sendback']);

        //a_sendback
                //0...n
                        //card_id, cnt_fails (0 means - solved), lvl
                      
//  console.log("sendback cnt="+Object.keys(rldata['sendback']['a_sendback']).length);
  


let achives_out={};

//АЧИВКА #1 УРОВНИ   lvlopen        
//Количество открытых уровней (В ШТУКАХ) просто сколько штук: ур3 и ур6 -> Всего 2 УРОВНЯ ОТКРЫТО!!!!  
 if (is_allow_log) {console.log("АЧИВКА #1 УРОВНИ");}
  let levels_in_sendback={};
    for (let i=0; i< Object.keys(rldata['sendback']['a_sendback']).length; i++)  {   
        let sendback_item=rldata['sendback']['a_sendback'][i];
        
        let is_level_yet_add=0;
        for (let j=0; j< Object.keys(levels_in_sendback).length; j++) {   
            let item=levels_in_sendback[j];
             
                if (item.toString()===sendback_item['lvl'].toString() )
                {
                    is_level_yet_add=1;
                    break;
                }
         
            }
            if (is_level_yet_add===0 && sendback_item['cnt_fails'].toString()==="0")  
            {
                var lastIndex = Object.keys(levels_in_sendback).length;
                levels_in_sendback[lastIndex] = sendback_item['lvl'].toString();
            } 
        
    }    
  
     let lvlopen=-1;
     if (Object.keys(levels_in_sendback).length>0)
     {
          lvlopen=Object.keys(levels_in_sendback).length;//не открыта ачивка   
     }
     console.log("ФОРМИРУЕМ lvlopen="+lvlopen); 
     lvlopen=lvlopen.toString();
     achives_out['lvlopen']={};
     achives_out['lvlopen']['curr_achive_lvl']=lvlopen;
     achives_out['lvlopen']['is_show']=(lvlopen === "-1"?false:true);
     achives_out['lvlopen']['header']=(lvlopen === "-1"?"":rldata['achives']['lvlopen']['levels'][lvlopen]['header']);
     achives_out['lvlopen']['text']=(lvlopen === "-1"?"":rldata['achives']['lvlopen']['levels'][lvlopen]['text']);
     achives_out['lvlopen']['img']=(lvlopen === "-1"?"":location.protocol+'//'+window.location.hostname+(location.port ? ':'+location.port: '')+rldata['achives']['lvlopen']['levels'][lvlopen]['img']);
     
     
     
     
     
 //АЧИВКА #2 Карточки    cardssolved
//Количество решеных карточек разных уровней 
 if (is_allow_log) {console.log("АЧИВКА #2 Карточки");}
   
 let cnt_cards=0;  
 for (let i=0; i< Object.keys(rldata['sendback']['a_sendback']).length; i++)  {   
        let sendback_item=rldata['sendback']['a_sendback'][i];
       
        
        if (sendback_item['cnt_fails'].toString()==="0")    //sendback_item['lvl']  
        {
             if (is_allow_log) {
            console.log("sendback_item, card_id="+sendback_item['card_id']+"|lvl="+sendback_item['lvl']);
            console.log(sendback_item);}
            
            cnt_cards=cnt_cards+1;
        }
        
    } 

    if (is_allow_log) {
        console.log("cnt_cards");
        console.log(cnt_cards);
    }
    
//rldata['achives']       100% filled  
    //cardssolved
            //levels
                    //1
                            //cnt,  text, header, img
    
     let cardscnt_levels={};
        for (let j=0; j< Object.keys(rldata['achives']['cardssolved']['levels']).length; j++) { 
            let key= Object.keys(rldata['achives']['cardssolved']['levels'])[j];
            //console.log(key);
            let achives_item=rldata['achives']['cardssolved']['levels'][key];
            //console.log(achives_item);
            cardscnt_levels['achivelvl_'+key.toString()] =  parseInt(achives_item['cnt']) ;//ПРОСТО С key.toString() в ИНТЕКСЕ НИХУЯ НЕ РАБОТАЕТ!       + Math.floor(Math.random() * 100
        }
    
    if (is_allow_log) {     
  console.log("cardscnt_levels");
     console.log(cardscnt_levels);  }    
     
const sort_cardscnt_levels = Object.fromEntries(
  Object.entries(cardscnt_levels)
    .sort(([,v1], [,v2]) =>   +v1 - +v2 )
);

 if (is_allow_log) {
  console.log("sort_cardscnt_levels");
console.log(sort_cardscnt_levels);   }

   
   let cardssolved=-1; 
      for (let k=0; k< Object.keys(sort_cardscnt_levels).length; k++) {
          let key= Object.keys(sort_cardscnt_levels)[k];
            //console.log(key);
            let a_item=sort_cardscnt_levels[key];
            
            //console.log("a_item|key="+key);
            //console.log(a_item);
            
            if (cnt_cards>a_item-1)
            {
                if (is_allow_log) {console.log("a_item|key="+key+" |||YES!!");}
                cardssolved= key.replace( 'achivelvl_', "");
                //console.log("cardssolved");
                //console.log(cardssolved);
            }
          
          
      }
   
   console.log("ФОРМИРУЕМ cardssolved="+cardssolved); 
   cardssolved=cardssolved.toString();
   
   achives_out['cardssolved']={};
   achives_out['cardssolved']['curr_achive_lvl']=cardssolved;
   achives_out['cardssolved']['is_show']=(cardssolved === "-1"?false:true);
   achives_out['cardssolved']['header']=(cardssolved === "-1"?"":rldata['achives']['cardssolved']['levels'][cardssolved]['header']);
   achives_out['cardssolved']['text']=(cardssolved === "-1"?"":rldata['achives']['cardssolved']['levels'][cardssolved]['text']);
   achives_out['cardssolved']['img']=(cardssolved === "-1"?"":location.protocol+'//'+window.location.hostname+(location.port ? ':'+location.port: '')+rldata['achives']['cardssolved']['levels'][cardssolved]['img']);
   
   if (is_allow_log) {
       console.log('cardssolved FIN');
       console.log(cardssolved);}
   
        
    
        
        
        
      
 //АЧИВКА #3 Баллы   olimpicswoked
//Количество баллов со всех решенных карточек разных уровней всего просто сумма баллов 
if (is_allow_log) {console.log("АЧИВКА #3 Баллы");}
   
 let sum_olimp=0;  
 for (let i=0; i< Object.keys(rldata['sendback']['a_sendback']).length; i++)  {   
        let sendback_item=rldata['sendback']['a_sendback'][i];
       
        
        if (sendback_item['cnt_fails'].toString()==="0")    //sendback_item['lvl']  
        {
            if (is_allow_log) {
                console.log("sendback_item, card_id="+sendback_item['card_id']+"|lvl="+sendback_item['lvl']);
                console.log(sendback_item);}
            sum_olimp=sum_olimp+parseInt(sendback_item['lvl']);
        }
        
    } 
if (is_allow_log) {
   console.log("sum_olimp");
   console.log(sum_olimp);}
    
//rldata['achives']       100% filled  
    //olimpicswoked
            //levels
                    //1
                            //cnt,  text, header, img
    
     let olimpsum_levels={};
        for (let j=0; j< Object.keys(rldata['achives']['olimpicswoked']['levels']).length; j++) { 
            let key= Object.keys(rldata['achives']['olimpicswoked']['levels'])[j];
            //console.log(key);
            let achives_item=rldata['achives']['olimpicswoked']['levels'][key];
            //console.log(achives_item);
            olimpsum_levels['achivelvl_'+key.toString()] =  parseInt(achives_item['cnt']) ;//ПРОСТО С key.toString() в ИНТЕКСЕ НИХУЯ НЕ РАБОТАЕТ!       + Math.floor(Math.random() * 100
        }
  if (is_allow_log) {      
  console.log("olimpsum_levels");
     console.log(olimpsum_levels);  }    
     
const sort_olimpsum_levels = Object.fromEntries(
  Object.entries(olimpsum_levels)
    .sort(([,v1], [,v2]) =>   +v1 - +v2 )
);
if (is_allow_log) {
  console.log("sort_olimpsum_levels");
console.log(sort_olimpsum_levels); }  

   
   let olimpicswoked=-1; 
      for (let k=0; k< Object.keys(sort_olimpsum_levels).length; k++) {
          let key= Object.keys(sort_olimpsum_levels)[k];
            //console.log(key);
            let a_item=sort_olimpsum_levels[key];
            
            //console.log("a_item|key="+key);
            //console.log(a_item);
            
            if (sum_olimp>a_item-1)
            {
                if (is_allow_log) {console.log("a_item|key="+key+" |||YES!!");}
                olimpicswoked= key.replace( 'achivelvl_', "");
                if (is_allow_log) {
                console.log("olimpicswoked");
                console.log(olimpicswoked);}
            }
          
          
      }
      
   console.log("ФОРМИРУЕМ olimpicswoked="+olimpicswoked); 
   olimpicswoked=olimpicswoked.toString();
   
   achives_out['olimpicswoked']={};
   achives_out['olimpicswoked']['curr_achive_lvl']=olimpicswoked;
   achives_out['olimpicswoked']['is_show']=(olimpicswoked === "-1"?false:true);
   achives_out['olimpicswoked']['header']=(olimpicswoked === "-1"?"":rldata['achives']['olimpicswoked']['levels'][olimpicswoked]['header']);
   achives_out['olimpicswoked']['text']=(olimpicswoked === "-1"?"":rldata['achives']['olimpicswoked']['levels'][olimpicswoked]['text']);
   achives_out['olimpicswoked']['img']=(olimpicswoked === "-1"?"":location.protocol+'//'+window.location.hostname+(location.port ? ':'+location.port: '')+rldata['achives']['olimpicswoked']['levels'][olimpicswoked]['img']);
   
   if (is_allow_log) {
     console.log('olimpicswoked FIN');
     console.log(olimpicswoked);}
   
        
     console.log("achives_out['is_no_achives']:"+lvlopen+"|"+cardssolved+"|"+olimpicswoked) ;
     achives_out['is_no_achives']= ((lvlopen === "-1" && cardssolved === "-1" && olimpicswoked === "-1" )? false : true);
        
    console.log("achives_out");
    console.log(achives_out);
    
   
   return achives_out;
 },

    
    
    
    
    
  },
  
  
  
  
};

var game=new Vue(_gameObj).$mount('#app');
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 