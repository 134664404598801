<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
    
   /*   
let Tawk_API = {};
let Tawk_LoadStart = new Date();
let s1 = document.createElement("script");
let s0 = document.getElementsByTagName("script")[0];
s1.async = true;
s1.src = 'https://embed.tawk.to/5f802263f0e7167d00177fdd/default'
s1.charset = 'UTF-8';
s1.setAttribute('crossorigin', '*');
s0.parentNode.insertBefore(s1, s0);
window.Tawk_API = Tawk_API;
window.Tawk_API.onLoad = function(){
    window.Tawk_API.hideWidget();   // hide the chat
}; */
   
    
    
    
 
 
 var t0 = {  
    aaa:"3",
}
 
 
 
export default {
  name: 'app', 
    
  
  components: {
  },   
  created() {
    
    
  },
}
</script>

<style lang="scss">
@import "../node_modules/@mdi/font/css/materialdesignicons.min.css";
@import "../node_modules/flag-icon-css/css/flag-icon.min.css";
@import "../node_modules/font-awesome/css/font-awesome.min.css";
@import "../node_modules/simple-line-icons/css/simple-line-icons.css";
@import "../node_modules/ti-icons/css/themify-icons.css";
@import "../node_modules/sweetalert2/dist/sweetalert2.min.css";
@import "../node_modules/vue-snotify/styles/material.css";
@import "../node_modules/codemirror/lib/codemirror.css";
@import "../node_modules/fullcalendar/dist/fullcalendar.css";
@import "../node_modules/c3/c3.min.css";
@import "../node_modules/chartist/dist/chartist.min.css";
@import "./assets/scss/style";
</style>
